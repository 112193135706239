import React, { useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import rewardbg from "../Assets/img/Bg/reward-bg.png";
import SeaUrchins from "../Assets/img/Sea/Sea-Urchins.png";
import Dolphin from "../Assets/img/Sea/DOLPHIN.png";
import BARRACUDA from "../Assets/img/Sea/BARRACUDA.png";
import STINGRAY from "../Assets/img/Sea/STINGRAY.png";
import SEALION from "../Assets/img/Sea/SEA-LION.png";
import WHALE from "../Assets/img/Sea/BLUE-WHALE.png";
import MEGALODON from "../Assets/img/Sea/MEGALODON.png";
import THEKRAKEN from "../Assets/img/Sea/THE-KRAKEN.png";
import { PresaleNowButton } from "../Components/Buttons";
import { Link } from "react-router-dom";
import RewardFishList from "../Components/RewardFishList";
function Rewards({ setSignupShow }) {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Rewards Starts Here */}
      <section className="inner-page rewards-page container-fluid">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${rewardbg})` }}
        ></div>
        <Row>
          <Container>
            <Row>
              <Col
                xxl={{ span: 8, offset: 2 }}
                xl={{ span: 10, offset: 1 }}
                className="text-center"
              >
                <h1>
                  Dividend <span>Scheme</span>
                </h1>
                <div className="spacer-20"></div>
                <h4 className="whitecolor">
                  We value our investor's trust and loyalty. <br />
                  So, Introducing Algotech's Rewards that allows you to earn a
                  share of our investment by investing in our trading platform.
                </h4>
                <div className="spacer-50"></div>
              </Col>
              <Col
                xxl={{ span: 8, offset: 2 }}
                xl={{ span: 10, offset: 1 }}
                className="text-center"
              >
                <h2>
                  Reward <span>Levels</span>
                </h2>
                <div className="spacer-50"></div>
                <div className="reward-picture-pnl">
                  <ul className="reward-picture-detail-pnl">
                    <li>
                      <div className="flex-div">
                        <div className="fish-pnl">
                          <div className="txt-pnl">
                            <h5>
                              <span className="lock-span"></span> Level 1
                            </h5>
                            <h6>Sea Urchins</h6>
                            {/* <span className="congrt-span">Congratulations</span> */}
                            <p>Achieve at 500,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>1% </span>
                              You receive of the companies profits
                            </p>
                          </div>
                          <div className="img-pnl">
                            <img src={SeaUrchins} alt="Sea Urchins" />
                          </div>
                        </div>
                        <div className="fish-pnl right">
                          <div className="img-pnl">
                            <img src={Dolphin} alt="Dolphin" />
                          </div>
                          <div className="txt-pnl">
                            <h5>
                              <span className="lock-span"></span> Level 2
                            </h5>
                            <h6>Dolphin</h6>
                            <p>Achieve at 1,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>1.5%</span>
                              You receive  of the companies profits
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="right-list">
                      <div className="flex-div">
                        <div className="fish-pnl">
                          <div className="txt-pnl">
                            <h5>
                              <span className="lock-span"></span> Level 3
                            </h5>
                            <h6>Barracuda</h6>
                            <p>Achieve at 2,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>2%</span>
                              You receive  of the companies profits
                            </p>
                          </div>
                          <div className="img-pnl">
                            <img src={BARRACUDA} alt="BARRACUDA" />
                          </div>
                        </div>
                        <div className="fish-pnl right">
                          <div className="img-pnl">
                            <img src={STINGRAY} alt="STINGRAY" />
                          </div>
                          <div className="txt-pnl">
                            <h5>
                              <span className="lock-span"></span> Level 4
                            </h5>
                            <h6>Stingray</h6>
                            <p>Achieve at 4,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>2.5%</span>
                              You receive   of the companies profits
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex-div">
                        <div className="fish-pnl">
                          <div className="txt-pnl">
                            <h5><span className="lock-span"></span> Level 5</h5>
                            <h6>
                               Sea Lion
                            </h6>
                            <p>Achieve at 6,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>3%</span>
                              You receive   of the companies profits
                            </p>
                          </div>
                          <div className="img-pnl">
                            <img src={SEALION} alt="SEA LION" />
                          </div>
                        </div>
                        <div className="fish-pnl right">
                          <div className="img-pnl">
                            <img src={WHALE} alt="WHALE" />
                          </div>
                          <div className="txt-pnl">
                            <h5> <span className="lock-span"></span> Level 6</h5>
                            <h6>
                              Blue Whale
                            </h6>
                      
                            <p>Achieve at 8,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>4%</span>
                              You receive   of the companies profits
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="right-list">
                      <div className="flex-div">
                        <div className="fish-pnl">
                          <div className="txt-pnl">
                          <h5><span className="lock-span"></span> Level 7</h5>
                            <h6>
                              Megalodon
                            </h6>
                            <p>Achieve at 10,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>6%</span>
                              You receive  of the companies profits
                            </p>
                          </div>
                          <div className="img-pnl">
                            <img src={MEGALODON} alt="MEGALODON" />
                          </div>
                        </div>
                        <div className="fish-pnl right">
                          <div className="img-pnl">
                            <img src={THEKRAKEN} alt="THE KRAKEN" />
                          </div>
                          <div className="txt-pnl">
                            <h5><span className="lock-span"></span> Level 8</h5>
                            <h6>
                               The Kraken
                            </h6>
                            <p>Achieve at 12,000,000</p>
                            <span className="brdr-span"></span>
                            <p>
                              <span>10%</span>
                              You receive  of the companies profits
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="spacer-50"></div>
                <h2>Get your new reward now!</h2>
                <PresaleNowButton  setSignupShow={setSignupShow}/>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Rewards Ends Here */}
      {/* <section className="container-fluid reward-pnl text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${rewardbg})` }}
        ></div>
        <Row>
          <Container className="zindex">
            <Row>
              <Col xl="12">
                <div className="spacer-50"></div>
              </Col>
              <Col xl={{ span: 6, offset: 3 }}>
                <h5 className="text-uppercase letter-spacing">Reward Levels</h5>
                <h2>
                  <span>Earn a Share</span> of Our Investment
                </h2>
                <div className="spacer-50"></div>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                <RewardFishList />
              </Col>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                <div className="spacer-40"></div>
                <Link className="reg-btn br-radius" to="javascript:void(0);">
                  Get your new reward <span className="color">NOW</span>!
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      </section> */}
      {/* Reward Panel Ends Here */}
    </>
  );
}
export default Rewards;
