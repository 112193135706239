import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import strategy from "../Assets/img/strategy.png";
import strategy1 from "../Assets/img/strategy (1).png"
import strategy2 from "../Assets/img/strategy (2).png";
import strategy3 from "../Assets/img/strategy (3).png";
function StrategiesPost() {
  return (
    <>
      <Col xl="3" lg="3" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={strategy} alt="Momentum Trading" />
          </div>
          <div className="txt-pnl">
            <h4>Momentum Trading</h4>
            <span></span>
            <p>
              Utilize momentum trading strategy to capitalize on price
              movements, identifying assets with strong upward or downward
              trends, and generating profits with precision
            </p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="3" md="6" sm="12">
        <div className="strategies-post">
          <div className="img-pnl">
            <img src={strategy1} alt="Mean Reversion" />
          </div>
          <div className="txt-pnl">
            <h4>Mean Reversion</h4>
            <span></span>
            <p>
              Mean reversion strategy captures price reversals in cryptocurrency
              markets, exploiting potential range-bound market opportunities
            </p>
          </div>
        </div>
      </Col>
      <Col xl="3" lg="3" md="6" sm="12">
        <div className="strategies-post">
          <div className="img-pnl">
            <img src={strategy2} alt="Momentum Trading" />
          </div>
          <div className="txt-pnl">
            <h4>Breakout Trading</h4>
            <span></span>
            <p>
              Utilize breakout trading strategy to capitalize on price
              breakouts, scanning markets for assets near support or resistance
              levels, and executing trades
            </p>
          </div>
        </div>
      </Col>
      <Col xl="3" lg="3" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={strategy3} alt="Momentum Trading" />
          </div>
          <div className="txt-pnl">
            <h4>Arbitrage Opportunities</h4>
            <span></span>
            <p>
              Arbitrage strategies exploit price discrepancies across multiple
              cryptocurrency exchanges, minimizing risks and maximizing
              potential gains
            </p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="3" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={strategy3} alt="Momentum Trading" />
          </div>
          <div className="txt-pnl">
            <h4 style={{marginBottom:'20px'}}>Trading Volatility Dynamics 
  </h4>      <span></span>
            <p>
            Volatility  trading  strategies  capitalize  on price  fluctuations  and volatility in the market. AlgoTech's volatility trading algorithms analyze volatility levels and patterns, enabling traders to enter and exit positions strategically based on volatility conditions, thus profiting from price swings.
      
           </p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="3" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={strategy3} alt="Momentum Trading" />
          </div>
          <div className="txt-pnl">
            <h4>Machine Learning-Based Strategies</h4>
            <span></span>
            <p>
            AlgoTech leverages machine learning algorithms to develop and deploy innovative trading strategies. These strategies use advanced machine learning models to analyze market data, identify patterns, and adapt to changing market conditions, enabling traders to benefit from adaptive and data-driven trading approaches. 
           </p>
          </div>
        </Link>
      </Col>
    </>
  );
}
export default StrategiesPost;
