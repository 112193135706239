import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Nav,
  Navbar,
  Button,
  Dropdown,
  Container,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Logo from "../Assets/img/Logo/logo.png";
import User from "../Assets/img/Icons/icon-user.png";
import flag1 from "../Assets/img/flags/icon-australia-flag.png";
import flag2 from "../Assets/img/flags/icon-uae.png";
import flag3 from "../Assets/img/flags/icon-united-states-flag.png";
import Emailbox from "../Assets/img/Icons/icon-envelpoe-1.png";
import {
  GetAllAvaliableCurrencies,
  GetPromoCode,
  GraphTotal,
  Login,
  ResetProfilePassword,
  SignUpWithEmail,
  Signup,
  ValidateOTPonLogin,
  forgetPasswordApi,
  getBonusTokenCountWithOutStage,
  getDetailofUserwithWallet,
  getTokenBonusCount,
} from "../Api";
import { Toast } from "bootstrap";
import { ToastContainer, toast } from "react-toastify";
import UserContest from "../ContextAPI/UserContest";
import {
  CurrenicesArray,
  Google_Script_Url,
  Languages,
  WhitePaperLink,
  colorRed,
  colorWhite,
  countryUrl,
  emailRegex,
  nameRegex,
  NumberRegex,
  passwordRegex,
  emailInputRegex,
} from "../Constants";
import { Notifications } from "./Toast";
// import iconerror from '../Assets'
import iconerror from "../Assets/img/admin-icons/icon-error.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PulseLoader } from "react-spinners";

import "../google.scss";

import { CountrySelector, PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import Select from "react-select";
import WalletTransaction from "../Pages/AdminPanel/WalletTransactions";
import { useWeb3ModalEvents } from "@web3modal/wagmi/react";
// import { useAccount   , useDisconnect } from 'wagmi'
import { useAccount, useDisconnect } from "wagmi";
import OTPInput from "react-otp-input";

function NavBar({
  setSignupShow,
  setctaModal,
  ctaModal,
  setloginShow,
  loginShow,
  forgetPassword,
  SignupShow,
  scrollToFeatured,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  const {
    CurrentStage,
    NavBaRSignUp,
    SetNavBaRSignUp,

    TotalSaleInStage,
    OverAllSaleToken,
    SetSelectedCurrency,
    SetSelectedvalue,
    SetshowTransaction,
    SetPromocode,
    Promocode,
    Promotioncode,
    SetPromotioncode,
    SelectedCurrency,
    Selectedvalue,
    SetUserDetail,
    UserDetail,
  } = useContext(UserContest);

  const navigate = useNavigate();
  const [UserId, setUserId] = useState();
  const { disconnect } = useDisconnect();

  const events = useWeb3ModalEvents();
  const [BonusToken, setBonusToken] = useState();

  const location = useLocation();
  // const address = ""
  const { address, isConnecting, isDisconnected } = useAccount();
  const [forgetPasswrodLoader, setforgetPasswrodLoader] = useState(false);

  const [emailloginShow, setemailloginShow] = useState(false);
  const [PasswordModal, setPasswordModal] = useState(false);
  const [PasswordOTP, setPasswordOTP] = useState(false);
  const [FactorLoader, setFactorLoader] = useState(false);
  const [tempToken, setTemptToken] = useState(false);

  const [dialedCode, setDialedCode] = useState();
  const [CurrentCountry, setCurrentCountry] = useState();
  const [SelectedCountryFlag, setSelectedCountryFlag] = useState();
  const [EmailReset, setEmailReset] = useState("");
  const [language, setlanguage] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [rememberMe, setRememberMe] = useState(false);
  const [userDeatilofaddress, setuserDeatilofaddress] = useState();
  const [LoginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const cokeisString = {
    rememberedEmail: "rememberedEmail",
    rememberedPassword: "rememberedPassword",
    rememberMe: "rememberMe",
  };

  useEffect(() => {
    // Load saved data from cookies on component mount
    const savedEmail = getCookie("rememberedEmail");
    const savedPassword = getCookie("rememberedPassword");
    const savedRememberMe = getCookie("rememberMe");
    // console.log(savedEmail, savedPassword, savedRememberMe, "savedRememberMe");

    if (savedRememberMe) {
      setLoginData((prev) => ({
        ...prev,
        email: savedEmail || "",
        password: savedPassword || "",
      }));
      setRememberMe(true);
    }
  }, [emailloginShow]);

  const handleLogin = () => {
    // Your login logic here

    // If "Remember Me" is checked, save email and password to cookies
    if (rememberMe) {
      setCookie(cokeisString.rememberedEmail, LoginData.email);
      setCookie(cokeisString.rememberedPassword, LoginData.password);
      setCookie(cokeisString.rememberMe, true);
    } else {
      // If "Remember Me" is not checked, remove saved data from cookies
      removeCookie(cokeisString.rememberedEmail);
      removeCookie(cokeisString.rememberedPassword);
      removeCookie(cokeisString.rememberMe);
    }

    // Continue with your login logic...
  };

  // Function to set a cookie
  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/`;
  };

  // Function to get the value of a cookie
  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };

  // Function to remove a cookie
  const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  useEffect(() => {
    GetPromoCode()
      .then((data) => {
        // console.log(data, "SetPromotioncode");
        SetPromotioncode(data);
      })
      .catch(() => {});

    // const sampleNumber = parsePhoneNumber('r675755', 'US');
    // const formattedSampleNumber = sampleNumber ? sampleNumber.formatNational() : '';
    // console.log(sampleNumber.isValid())
    fetch(countryUrl)
      .then((response) => response.json())
      .then((data) => {
        setCurrentCountry(data.userSelectValue.value);
        setSelectedCountryFlag(data.userSelectValue.label.split(" ")[0]);
        // console.log(data.userSelectValue.label.split(' ')[0])
      })
      .catch(() => {});
  }, []);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const [phone, setPhone] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    validatePhone();
  }, [phone]);

  const validatePhone = () => {
    try {
      const parsedPhone = phoneUtil.parseAndKeepRawInput(
        phone,
        CurrentCountry?.toLocaleLowerCase()
      ); // Specify the default region code
      const isValidPhone = phoneUtil.isValidNumber(parsedPhone);
      setIsValid(isValidPhone);
    } catch (error) {
      setIsValid(false);
    }
  };
  useEffect(() => {
    if (location.pathname.split("/")[1] === "forgetpassword") {
      setforgetPasswordModal(true);
      setforgetPasswordData((prev) => ({
        ...prev, // Correcting the typo here
        email: location.pathname.split("/")[2], // Correcting the typo here
      }));
      setOTP(location.pathname.split("/")[3]);
    }
    // alert(location.pathname)
  }, [location.pathname]);

  const [showPassword, setShowPassword] = useState(false);
  const [forgetPasswordModal, setforgetPasswordModal] = useState(false);
  const [forgetPasswordData, setforgetPasswordData] = useState({
    password: "",
    repeatPassword: "",
  });
  const [forgetPassworerror, setforgetPassworerror] = useState({
    passwordError: "",
    repeatPasswordError: "",
  });
  const [OTP, setOTP] = useState();

  const [signUpData, setSignUpData] = useState({
    email: "",
    name: "",
    password: "",
    phoneNumber: "",
    repeatPassword: "",
  });

  const [signUpDataError, setSignUpDataError] = useState({
    emailError: "",
    nameError: "",
    passwordError: "",
    phoneNumberError: "",
    repeatPasswordError: "",
  });
  const [loginLoader, setLoginLoader] = useState(false);

  const [connectwalletShow, setconnectwalletShow] = useState(false);

  const [forgotpasswordShow, setforgotpasswordShow] = useState(false);
  // const [SignupShow, setSignupShow] = useState(false);
  const [CreateAcoountWithEmail, setCreateAcoountWithEmail] = useState(false);
  const [saleGraph, setsaleGraph] = useState();
  const [percentage, setPercentage] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  const [firstAttemp, setFirstAttemp] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(false);
  const [forgetPasswordloader, setforgetPasswordloader] = useState(false);

  const EmailLoginButtonClick = () => {
    setemailloginShow(true);
    setloginShow(false);
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setSignupShow(false);
  };
  const ForgetpasswordButtonClick = () => {
    setforgotpasswordShow(true);
    setemailloginShow(false);
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setloginShow(false);
  };
  const LoginButtonClick = () => {
    setloginShow(true);
    setSignupShow(false);
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setforgotpasswordShow(false);
  };
  useEffect(() => {
    if (NavBaRSignUp) {
      SignUpButtonClick();
    }
  }, [NavBaRSignUp]);
  const SignUpButtonClick = () => {
    SetPromocode("");
    setSignupShow(true);
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setforgotpasswordShow(false);
    setconnectwalletShow(false);
    setemailloginShow(false);
  };
  const ConnectWalletButtonClick = () => {
    setconnectwalletShow(true);
    setloginShow(false);
  };

  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased) + 120000);
        })
        .catch(() => {});

    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased) + 120000);
      })
      .catch(() => {});

    GraphTotal()
      .then((data) => {
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    SetPromocode(ref);
    if (ref) setSignupShow(true);
    // console.log('Ref:', ref);
  }, [CurrentStage]);

  useEffect(() => {
    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)

    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));
    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));

    //  setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const validateName = (name) => {
    return nameRegex.test(name);
  };

  useEffect(() => {
    if (firstAttemp) {
      // return phoneNumberObj && phoneNumberObj.isValid();c

      const { email, name, password, phoneNumber, repeatPassword } = signUpData;
      // const phoneNumberObj = parsePhoneNumber(phoneNumber, CurrentCountry);

      const hasSpaceInName = name.includes("");

      let errors = {
        emailError: validateEmail(email) ? "" : "Invalid email format",
        // passwordError: validatePassword(password)
        //   ? ""
        //   : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
        // phoneNumberError: phoneNumber ? PhoneInput.isValidPhoneNumber(phoneNumber) ? "" : 'Phone number is not valid ' : "",
        repeatPasswordError:
          password === repeatPassword ? "" : "Passwords do not match",
      };
      // handleOnChange(phoneNumber)
      setSignUpDataError(errors);
    }
  }, [signUpData]);
  const SignUpFunction = (temp) => {
    setLoginLoader(true);
    if (privacySelected)
      Signup(temp)
        .then(() => {
          setLoginLoader(false);
          if (Selectedvalue && SelectedCurrency) navigate("/PurchaseToken");
          else navigate("/Dashboard");
          setSignupShow(false);
        })
        .catch(() => {
          setLoginLoader(false);
        });
    else Notifications("error", "Please check privacy policy");
    setLoginLoader(false);
  };

  const SignUpFunctionWithAddress = (temp) => {
    setLoginLoader(true);
    temp = {
      ...signUpData,
      address: address,
    };
    if (privacySelected)
      SignUpWithEmail(temp)
        .then(() => {
          disconnect();
          setLoginLoader(false);
          if (Selectedvalue && SelectedCurrency) navigate("/PurchaseToken");
          else navigate("/Dashboard");
          setSignupShow(false);
        })
        .catch(() => {
          setLoginLoader(false);
        });
    else Notifications("error", "Please check privacy policy");
    setLoginLoader(false);
  };
  useEffect(() => {
    const { password, repeatPassword } = forgetPasswordData;

    let errors = {
      // passwordError: validatePassword(password)
      //   ? ""
      //   : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
      repeatPasswordError:
        password === repeatPassword ? "" : "Passwords do not match",
    };
    // alert('zaz')
    // console.log(errors )
    setforgetPassworerror(errors);
  }, [forgetPasswordData]);
  // const HandleForgetError = ()=>{

  //   const {  password, repeatPassword } = forgetPasswordData;

  //   let errors = {
  //     passwordError: validatePassword(password) ? "" : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
  //     repeatPasswordError: password === repeatPassword ? "" : "Passwords do not match"
  //   };
  //   setforgetPassworerror(errors)

  // }

  const handleValidation = (check) => {
    // console.log()
    // Toast("Email is alreay taken")
    const { email, name, password, phoneNumber, repeatPassword } = signUpData;

    // console.log("phoneNumber", phoneNumber.split(''))
    // const phoneNumberObj = parsePhoneNumber(phoneNumber, CurrentCountry);

    const hasSpaceInName = name.includes(" ");

    let errors = {
      emailError: validateEmail(email) ? "" : "Invalid email format",

      // passwordError: validatePassword(password)
      //   ? ""
      //   : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
      // phoneNumberError: phoneNumber ? PhoneInput.isValidPhoneNumber(phoneNumber) ? "" : 'Phone number is not valid ' : "",
      repeatPasswordError:
        password === repeatPassword ? "" : "Passwords do not match",
    };
    // handleOnChange(phoneNumber)
    // here if no error found and all fields are  ok alert ok
    setFirstAttemp(true);

    const noErrors = Object.values(errors).every((error) => error === "");

    // If no errors, show an "OK" alert
    let temp;
    if (Promocode) {
      temp = {
        ...signUpData,
        referral_link: Promocode,
      };
    } else {
      temp = signUpData;
    }

    if (noErrors) {
      if (SelectedCurrency) {
        if (Selectedvalue) {
          if (!check) {
            SignUpFunction(temp);
          } else {
            SignUpFunctionWithAddress(temp);
          }
        } else {
          Notifications("error", "Please enter amount");
        }
      } else {
        if (!check) {
          SignUpFunction(temp);
        } else {
          SignUpFunctionWithAddress(temp);
        }
      }
    }
    // If no errors, show an "OK" alert

    setSignUpDataError(errors);
  };

  const LoginFun = () => {
    setLoginLoader(true);
    handleLogin();
    Login(LoginData)
      .then((data) => {
        setLoginLoader(false);
        // console.log(data,'LoginData' )
        SetUserDetail(data);
        setUserId(data?.Data?.id);
        if (data?.Data?.twofactor) {
          setTemptToken(data?.token);
          setPasswordModal(true);
          Notifications("success", " OTP has sent to your Registered Email");
        } else {
          if (data?.token) localStorage.setItem("JwtToken", data?.token);
          navigate("/Dashboard");
          Notifications("success", "User Logged In Successfully");
        }
      })
      .catch(() => {
        setLoginLoader(false);
      });
  };

  const Check_OTP_OnLogin = () => {
    setFactorLoader(true);
    const data = {
      user_id: UserId,
      otp: PasswordOTP,
    };
    ValidateOTPonLogin(data)
      .then(() => {
        setFactorLoader(false);
        localStorage.setItem("JwtToken", tempToken);

        navigate("/Dashboard");
        Notifications("success", "User Logged In Successfully");
      })
      .catch(() => {
        setFactorLoader(false);
        Notifications("error", "OTP Login Failed");
      });
  };
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "btc",
    value: "btc",
  });
  const [currencies, setCurrencies] = useState([]);

  // const options = currencies.map((item) => ({
  //   label: item.currency,
  //   value: item.currency,
  // }));
  const options = CurrenicesArray.map((item) => ({
    label: item.label.toUpperCase(),
    image: item.image,
    value: item.label,
  }));
  useEffect(() => {
    GetAllAvaliableCurrencies()
      .then((currencies) => {
        // console.log(currencies)
        setCurrencies(currencies.currencies);
      })
      .catch(() => {});
  }, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,

      borderRadius: "20px",
      backgroundColor: "transparent",
      // height:'42px',
      color: "white",
      // innerHeight:'42px',
      outerHeight: "42px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const HandleForgetPassword = () => {
    setforgetPasswordloader(true);
    const noErrors = Object.values(forgetPassworerror).every(
      (error) => error === ""
    );
    if (noErrors) {
      const data = {
        email: forgetPasswordData?.email,
        newpassword: forgetPasswordData?.password,
        otp: OTP,
      };
      if (!forgetPasswordloader) {
        setforgetPasswordloader(true);

        ResetProfilePassword(data)
          .then(() => {
            setforgetPasswordloader(false);
            setforgetPasswordModal(false);

            Notifications("success", "Password updated Successfully");
          })
          .catch(() => {
            setforgetPasswordloader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (address) {
      setloginShow(false);
    }
  }, [address]);

  const ResetPasswordFunction = () => {
    if (!forgetPasswordloader) {
      setforgetPasswrodLoader(true);
      forgetPasswordApi({ email: EmailReset })
        .then((x) => {
          setforgetPasswrodLoader(false);
          Notifications("success", "Email sent Successfully");
        })
        .catch(() => {
          setforgetPasswrodLoader(false);
          Notifications("error", "No Account Found");
        });
    }
  };
  useEffect(() => {
    try {
      const cookie = document?.cookie
        ?.split(";")
        ?.find((cookie) => cookie?.includes("googtrans"));

      if (cookie) {
        const languageFromCookie = cookie.split("/")[2];

        if (languageFromCookie) {
          setSelectedLanguage(languageFromCookie);
        }
      }
    } catch (error) {
      // console.error("Error while processing cookie:", error);
    }
  }, []);

  function removeCookie2(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  const removeCookie3 = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
  };

  function setCookie2(key, value, expiry) {
    // console.log("mazak ha key", key)
    removeCookie2(key);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie?.split(";")[0];
        // console.log("mazak ha existing existingCookie?split[0]", existingCookie?.split(";")[0])
        // console.log("mazak ha existing Cookie", existingCookie)
        // console.log("mazak ha existing split ", cookies)

        // Check if the key already exists in the cookies
        var updatedCookie = false;
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            // Update the existing cookie value
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key + "=" + value + "; expires=" + expiry + "; path=/";
            break;
          }
        }

        // console.log("mazak ha updated updatedCookie", updatedCookie)

        if (!updatedCookie) {
          // Create a new cookie if the key doesn't exist
          document.cookie =
            key + "=" + value + "; expires=" + expiry + "; path=/";
        }
        resolve();
      }, 1000);
    });
  }
  function setCookie3(key, value, expiry, domain) {
    // console.log(key, value, expiry,domain )
    removeCookie3(key, domain);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie.split(";");

        var updatedCookie = false;

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key +
              "=" +
              value +
              "; expires=" +
              expiry +
              "; path=/; domain=" +
              domain;
            break;
          }
        }

        if (!updatedCookie) {
          document.cookie =
            key +
            "=" +
            value +
            "; expires=" +
            expiry +
            "; path=/; domain=" +
            domain;
        }

        resolve();
      }, 1000);
    });
  }

  const googleTranslateElementInit = () => {
    const element = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,ar,zh-CN,fr,de,id,it,ko,pt,es,th,vi",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    // console.log(document.documentElement.lang,"language")

    const interval = setInterval(() => {
      if (
        document.documentElement.lang === localStorage.getItem("Language") ||
        (document.documentElement.lang === "en" &&
          localStorage.getItem("Language") === "lo")
      ) {
        clearInterval(interval);
      }
    }, [1000]);
  }, []);

  useEffect(() => {
    // console.log(" googleTranslateElementInit true")
    var addScript = document.createElement("script");
    addScript.setAttribute("src", Google_Script_Url);
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // console.log(" googleTranslateElementInit false")
  }, [language]);

  useEffect(() => {
    setloginShow(false);
  }, [events]);
  useEffect(() => {
    if (address)
      getDetailofUserwithWallet(address)
        .then((data) => {
          setuserDeatilofaddress(data);
          // console.log(data, 'setuserDeatilofaddress')
          // console.log(data, 'setuserDeatilofaddress');
        })
        .catch(() => {
          // Handle error if needed
        });
  }, [isDisconnected, isConnecting, address]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isDisconnected && !isConnecting && address && userDeatilofaddress) {
        const { email } = userDeatilofaddress;
        if (email && !email.includes("@")) {
          setCreateAcoountWithEmail(true);
        }
      }
    }, 60000); // 1 minute interval in milliseconds

    return () => {
      // Cleanup function to clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [isDisconnected, isConnecting, address, userDeatilofaddress]);
  useEffect(() => {
    // Get all input fields
    const inputFields = document.querySelectorAll("input");

    // Loop through each input field and set autocomplete to 'off'
    inputFields.forEach((inputField) => {
      inputField.setAttribute("autocomplete", "off");
    });
  }, []);
  return (
    <>
      <div className="top-line">
        {Promotioncode?.name && (
          <p>
            Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
            {Promotioncode?.bonus}% <span>bonus </span>
            on any deposit!
          </p>
        )}
      </div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>

          <div style={{ display: "flex" }}>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <div
              id="google_translate_element"
              style={{ display: "none" }}
            ></div>
          </div>
          <Navbar.Collapse id="navbarScroll">
            <>
              <Nav className="me-auto my-2 my-lg-0">
                <Nav.Link
                  href="/"
                  // className={`${
                  //   useLocation().pathname == "/About" ? "active" : ""
                  // }`}
                >
                  About
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => scrollToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Features" ? "active" : ""
                  // }`}
                >
                  Features
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => RewardToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Rewards" ? "active" : ""
                  // }`}
                >
                  Rewards
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => TokenToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Tokemics" ? "active" : ""
                  // }`}
                >
                  tokenomics
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => RoadToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Roadmap" ? "active" : ""
                  // }`}
                >
                  roadmap
                </Nav.Link>
                <Nav.Link
                  href={WhitePaperLink}
                  target="_blank"
                  // className={`${
                  //   useLocation().pathname == "/Whitepaper" ? "active" : ""
                  // }`}
                >
                  whitepaper
                </Nav.Link>
                <Nav.Link
                  href="/FAQs"
                  onClick={() => FaqToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Faq" ? "active" : ""
                  // }`}
                >
                  FAQ
                </Nav.Link>
                <Nav.Link href="/Giveaway">Win-$250k</Nav.Link>
                <Nav.Link href="/blog">Blog</Nav.Link>
                <div className="mobile-view">
                  <Link
                    to="javascript:void(0);"
                    className="reg-btn-connect br-radius"
                  >
                    <w3m-connect-button />
                  </Link>
                  <Link onClick={() => setloginShow(true)}>
                    <img src={User} alt="User Icon" /> Sign in
                  </Link>
                </div>
              </Nav>
            </>
          </Navbar.Collapse>

          <div className="web-view-md">
            {!isDisconnected && !isConnecting ? (
              <>
                <Link
                  to="javascript:void(0);"
                  className="reg-btn small br-radius"
                >
                  {address?.slice(0, 5) + "..." + address?.slice(-5)}
                </Link>
                <Button
                  onClick={async () => {
                    disconnect();
                    //  window.location.reload()
                  }}
                >
                  <img src={User} alt="User Icon" /> Disconnect
                </Button>
              </>
            ) : (
              <>
                {localStorage.getItem("JwtToken") ? (
                  <>
                    <Link
                      to="javascript:void(0);"
                      className="reg-btn small br-radius"
                      onClick={() => navigate("/Dashboard")}
                    >
                      Dashboard
                    </Link>
                  </>
                ) : (
                  <>
                    <Button className="cnt-btn" onClick={() => setloginShow(true)}>
                      <w3m-connect-button />
                    </Button>
                    <Button onClick={() => setloginShow(true)}>
                      <img src={User} alt="User Icon" /> Sign in
                    </Button>
                  </>
                )}
              </>
            )}

            <Dropdown className="navbar-dropdown notranslate">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedLanguage ? (
                  Languages.map((item) => {
                    if (selectedLanguage && item.symbol === selectedLanguage) {
                      return (
                        <React.Fragment key={item.symbol}>
                          {item && <img src={item.image} />}
                        </React.Fragment>
                      );
                    }
                    return null; // Returning null when the condition is not met
                  })
                ) : (
                  <img
                    src={Languages.find((item) => item?.symbol === "en").image}
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Languages.map((item, index) => (
                  <Dropdown.Item
                    href={void 0}
                    key={item.symbol}
                    onClick={async () => {
                      localStorage.setItem("Language", item?.symbol);
                      setlanguage(item?.symbol);
                      await setCookie2("googtrans", `/en/${item.symbol}`, 1);
                      await setCookie3(
                        "googtrans",
                        `/en/${item.symbol}`,
                        1,
                        ".algotech.trade"
                      );

                      window.location.reload();
                    }}
                  >
                    <img src={item.image} alt="flag" />{" "}
                    {item?.symbol?.toUpperCase()}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>

      {/* Login Modal Starts Here */}
      <Modal
        size="md"
        show={loginShow}
        centered
        onHide={() => setloginShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="log-panel">
          <Button className="close-btn" onClick={() => setloginShow(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <h3>Login</h3>
          <p>
            To log in please use the method you used during the initial account
            opening process
          </p>
          <Link
            to="javascript:void(0);"
            className="reg-btn br-radius"
            onClick={() => SignUpButtonClick()}
          >
            Join Now
          </Link>
          <h6>OR</h6>
          <Button
            className="reg-btn br-radius trans"
            onClick={() => EmailLoginButtonClick()}
          >
            <i className="fa fa-envelope"></i> Login with email
          </Button>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={ctaModal}
        centered
        onHide={() => setctaModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="log-panel">
          <Button className="close-btn" onClick={() => setctaModal(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <h3>Login</h3>
          <p>
            To log in please use the method you used during the initial account
            opening process
          </p>
          <Link
            style={{ padding: "2px" }}
            to="javascript:void(0);"
            className="reg-btn br-radius centerr"
          >
            <w3m-connect-button />
          </Link>
          <h6>OR</h6>
          <Link
            style={{ color: "white" }}
            to="javascript:void(0);"
            className="reg-btn br-radius"
            onClick={() => SignUpButtonClick()}
          >
            Join Now
          </Link>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>

      {/* Login Modal Ends Here */}

      {/* Connect Wallet Modal Starts Here */}
      <Modal
        size="md"
        show={connectwalletShow}
        centered
        onHide={() => setconnectwalletShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel">
          <Button
            className="close-btn"
            onClick={() => setconnectwalletShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Login</h3>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                autoComplete="off"
                className="input-email"
                placeholder="Enter Your Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                autocomplete="off"
                className="input-name"
                placeholder="Enter Your name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="number"
                autoComplete="off"
                placeholder="Enter your connect number"
              />
            </Form.Group>
            <div className="text-right">
              <Button
                to="/"
                className="simple-link purple"
                onClick={() => setconnectwalletShow(false)}
              >
                Skip
              </Button>
            </div>
          </Form>
          <div className="spacer-40"></div>
          <Button className="reg-btn br-radius" to="/">
            Login
          </Button>
          <div className="spacer-40"></div>
          <p>
            Still don't have an account?{" "}
            <Button
              className="simple-link purple"
              onClick={() => SignUpButtonClick()}
            >
              Sign-Up here
            </Button>
          </p>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>
      {/* Connect Wallet Modal Ends Here */}

      {/* Login WIth Email Modal Starts Here */}
      <Modal
        size="md"
        show={emailloginShow}
        centered
        onHide={() => setemailloginShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel">
          <Button
            className="close-btn"
            onClick={() => setemailloginShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Login</h3>

          <Form autoComplete="off">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>

              <input
                value={LoginData?.email}
                onChange={(e) => {
                  const inputVal = e.target.value;
                  if (emailInputRegex.test(inputVal) || inputVal === "") {
                    setLoginData((prev) => ({
                      ...prev,
                      email: inputVal,
                    }));
                  }
                }}
                autoComplete="new-password"
                type="text"
                id="Email-login"
                className="input-email form-control"
                placeholder="Enter Your Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <div className="log-i-cntnr">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={LoginData?.password}
                  onChange={(e) => {
                    setLoginData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      LoginFun(); // Call your login function here
                    }
                  }}
                  autoComplete="off"
                  className="input-password"
                  placeholder="Enter your password"
                />
                {!showPassword ? (
                  <FaEyeSlash
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <FaEye
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
                {/* className="log-i-cntnr" */}
              </div>
            </Form.Group>
            <div className="flex-div">
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    id={`default-${type}`}
                    label={`Remember me`}
                  />
                </div>
              ))}
              <Button
                to="/"
                className="simple-link purple"
                onClick={() => ForgetpasswordButtonClick()}
              >
                Forgot password
              </Button>
            </div>
          </Form>
          <div className="spacer-40"></div>
          {}
          <Button
            onClick={() => {
              if (!loginLoader) LoginFun();
            }}
            className="reg-btn br-radius"
            to="/"
          >
            {loginLoader ? (
              <PulseLoader size={11} color={colorWhite} />
            ) : (
              "     Login"
            )}
          </Button>
          <div className="spacer-40"></div>
          <p>
            Still don't have an account?{" "}
            <Button
              className="simple-link purple"
              onClick={() => SignUpButtonClick()}
            >
              Sign-Up here
            </Button>
          </p>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>
      {/* Login WIth Email Modal Ends Here */}

      {/* Login WIth Email Modal Starts Here */}
      <Modal
        size="md"
        show={forgotpasswordShow}
        centered
        onHide={() => setforgotpasswordShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel">
          <Button
            className="close-btn"
            onClick={() => setforgotpasswordShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Forget Password</h3>
          <p>
            Provide your email address and we'll send you instructions to reset
            your password.
          </p>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>

              <input
                value={EmailReset}
                onChange={(e) => {
                  const inputVal = e.target.value;
                  if (emailInputRegex.test(inputVal) || inputVal === "") {
                    setEmailReset(e.target.value);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && emailRegex.test(EmailReset)) {
                    ResetPasswordFunction();
                  }
                }}
                autoComplete="new-password"
                type="email"
                className="input-email form-control"
                placeholder="Enter Your Email"
              />
              {!emailRegex.test(EmailReset) && EmailReset && (
                <p style={{ color: colorRed }}>Email is not valid</p>
              )}
            </Form.Group>
          </Form>
          <Button
            disabled={!emailRegex.test(EmailReset)}
            className="reg-btn br-radius"
            onClick={() => {
              ResetPasswordFunction();
            }}
            to={void 0}
          >
            {forgetPasswrodLoader ? (
              <PulseLoader size={11} color="white" />
            ) : (
              "Send Reset Link"
            )}
          </Button>
          <div className="spacer-30"></div>
          <Button
            to="/"
            className="simple-link purple mb-2"
            onClick={() => LoginButtonClick()}
          >
            Return to Login
          </Button>
          <p>
            Still don't have an account?{" "}
            <Button
              className="simple-link purple"
              onClick={() => SignUpButtonClick()}
            >
              Sign-Up here
            </Button>
          </p>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={forgetPasswordModal}
        centered
        onHide={() => setforgetPasswordModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel">
          <Button
            className="close-btn"
            onClick={() => setforgetPasswordModal(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Forget Password</h3>
          <p>Provide your email address and enter new password</p>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                autoComplete="new-password"
                disabled={true}
                value={forgetPasswordData.email}
                onChange={(e) => {
                  setforgetPasswordData((prev) => ({
                    ...prev, // Correcting the typo here
                    email: e.target.value, // Correcting the typo here
                  }));
                }}
                className="input-email"
                placeholder="Enter Your Email"
              />

              {!emailRegex.test(forgetPasswordData.email) && emailRegex && (
                <p style={{ color: colorRed }}>Email is not valid</p>
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <div className="log-i-cntnr">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  autocomplete="off"
                  value={forgetPasswordData.password}
                  onChange={(e) => {
                    setforgetPasswordData((prev) => ({
                      ...prev, // Correcting the typo here
                      password: e.target.value, // Correcting the typo here
                    }));
                  }}
                  className="input-password"
                  placeholder="Enter Your Password"
                />
                {!showPassword ? (
                  <FaEyeSlash
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <FaEye
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
              </div>

              {forgetPasswordData.password && (
                <p style={{ color: colorRed }}>
                  {forgetPassworerror.passwordError}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Repeat Password</Form.Label>
              <div className="log-i-cntnr">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  autocomplete="off"
                  value={forgetPasswordData.repeatPassword}
                  onChange={(e) => {
                    setforgetPasswordData((prev) => ({
                      ...prev, // Correcting the typo here
                      repeatPassword: e.target.value, // Correcting the typo here
                    }));
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && emailRegex.test(EmailReset)) {
                      if (!forgetPasswordloader) HandleForgetPassword();
                    }
                  }}
                  className="input-password"
                  placeholder="Enter Your Password"
                />
                {!showPassword ? (
                  <FaEyeSlash
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <FaEye
                    className="FaEye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
              </div>
              {forgetPasswordData.repeatPassword && (
                <p style={{ color: colorRed }}>
                  {forgetPassworerror.repeatPasswordError}
                </p>
              )}
            </Form.Group>
          </Form>
          <Button
            disabled={
              forgetPassworerror.passwordError ||
              forgetPassworerror.repeatPasswordError ||
              !emailRegex.test(forgetPasswordData.email)
            }
            onClick={() => {
              if (!forgetPasswordloader) HandleForgetPassword();
            }}
            className="reg-btn br-radius"
            to="/"
          >
            {forgetPasswordloader ? (
              <PulseLoader size={11} color={colorWhite} />
            ) : (
              "Update Password"
            )}
          </Button>
          <div className="spacer-30"></div>
          <Button
            to="/"
            className="simple-link purple mb-2"
            onClick={() => LoginButtonClick()}
          >
            Return to Login
          </Button>

          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2023 All Rights Reserved</span>
        </Modal.Body>
      </Modal>
      {/* Login WIth Email Modal Ends Here */}

      {/* Sign Up Modal Starts Here */}
      <Modal
        size="xl"
        show={SignupShow}
        centered
        onHide={() => setSignupShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel sign-up">
          <Button className="close-btn" onClick={() => setSignupShow(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <h3>Create your account</h3>
          <ul className="text-list">
            <li>
              <p>Current Stage</p>
              <p>
                <b className="notranslate">{CurrentStage?.level}</b>
              </p>
            </li>
            <li>
              <p>Current Price</p>
              <p>
                <b className="notranslate"> {CurrentStage?.price_of_token} </b>{" "}
                USDT
              </p>
            </li>
            <li>
              <p>Tokens Sold</p>
              <p>
                <b className="notranslate">{percentage}</b> %
              </p>
            </li>
            {Promocode && (
              <li>
                <p>Using Referral</p>
                <p>
                  <b className="notranslate">{Promocode}</b>
                </p>
              </li>
            )}
          </ul>
          <Form>
            <Row>
              <Col xl="4" lg="4" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={signUpData.name}
                    onChange={(e) => {
                      setSignUpData((prev) => ({
                        ...prev, // Correcting the typo here
                        name: e.target.value, // Correcting the typo here
                      }));
                    }}
                    type="text"
                    autoComplete="off"
                    className="input-name"
                    placeholder="Enter Your name"
                  />
                  <p className="color-red"> {signUpDataError.nameError} </p>
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <input
                    value={signUpData.email}
                    onChange={(e) => {
                      const inputVal = e.target.value;
                      if (emailInputRegex.test(inputVal) || inputVal === "") {
                        setSignUpData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }
                    }}
                    autoComplete="new-password"
                    type="email"
                    className="input-email form-control"
                    placeholder="Enter Your Email"
                  />
                  <p className="color-red"> {signUpDataError.emailError} </p>
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone number </Form.Label>
                  <div className="log-i-cntnr">
                    <PhoneInput
                      defaultCountry={CurrentCountry?.toLocaleLowerCase()}
                      value={phone}
                      onChange={(inputValue, country) => {
                        setDialedCode(country.country.dialCode);
                        // console.log(inputValue, country)
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          phoneNumber: inputValue, // Correcting the typo here
                        }));
                        setPhone(inputValue);
                      }}
                    />
                    {signUpData.phoneNumber === `+${dialedCode}` ? (
                      <></>
                    ) : (
                      <>
                        {!isValid && (
                          <div style={{ color: colorRed }}>
                            Phone is not valid
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Password</Form.Label>
                  <div className="log-i-cntnr">
                    <Form.Control
                      value={signUpData.password}
                      onChange={(e) => {
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          password: e.target.value, // Correcting the typo here
                        }));
                      }}
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      className="input-password"
                      placeholder="Enter your password"
                    />
                    {!showPassword ? (
                      <FaEyeSlash
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <FaEye
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </div>
                  <p className="color-red"> {signUpDataError.passwordError} </p>
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Repeat Password</Form.Label>
                  <div className="log-i-cntnr">
                    <Form.Control
                      value={signUpData.repeatPassword}
                      onChange={(e) => {
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          repeatPassword: e.target.value, // Correcting the typo here
                        }));
                      }}
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      className="input-password"
                      placeholder="Repeat your password"
                    />
                    {!showPassword ? (
                      <FaEyeSlash
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <FaEye
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                    {/* className="log-i-cntnr" */}
                  </div>
                  <p className="color-red">
                    {" "}
                    {signUpDataError.repeatPasswordError}{" "}
                  </p>
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Label>Select token ( Optional )</Form.Label>

                <Form.Select
                  onChange={(e) => {
                    SetSelectedCurrency(
                      options.find((item) => item.value === e.target.value)
                    );
                  }}
                  className="form-control token-select mb-4"
                  aria-label="Default select example"
                >
                  <option>-- Select Token --</option>
                  {options.map((item, index) => {
                    return (
                      <option value={item.value}>
                        {item.label.toUpperCase()}
                      </option>
                    );
                  })}
                </Form.Select>

                <div className="NavSelect">
                  {/* 
                  <Select
                    // className="form-control token-select"
                    value={SelectedCurrency}
                    onChange={(selectedOption) => SetSelectedCurrency(selectedOption)}
                    options={options}
                    styles={customStyles}
                    placeholder="Select Currency"
                    isSearchable
                  /> */}
                </div>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Purchase amount ( Optional ) </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!selectedCurrency}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only numbers from 0 to 9 and an empty string (for backspace)
                      if (NumberRegex.test(inputValue)) {
                        SetSelectedvalue(inputValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      // Allow only numbers from 0 to 9 and backspace
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                        e.preventDefault();
                      }
                    }}
                    autocomplete="off"
                    className="input-dollar"
                    placeholder="Enter purchase amount"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="mb-2 mt-2"
              controlId="exampleForm.ControlInput1"
            >
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type={type}
                    checked={receiveEmail}
                    onChange={() => {
                      setReceiveEmail(!receiveEmail);
                    }}
                    id="recieve"
                    label={`I agree to receive email notifications from AlgoTech with order information, special offers, and updates.`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type={type}
                    checked={privacySelected}
                    onChange={() => {
                      setPrivacySelected(!privacySelected);
                    }}
                    id="Agree"
                    label={
                      <>
                        I agree to the{" "}
                        <Link
                          to="/TermsConditions"
                          target="_blank" // This opens the link in a new tab/window
                          rel="noopener noreferrer" // Recommended for security reasons
                          className="simple-link purple"
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          target="_blank" // This opens the link in a new tab/window
                          rel="noopener noreferrer" // Recommended for security reasons
                          to="/PrivacyPolicy"
                          className="simple-link purple"
                        >
                          Privacy Policy
                        </Link>
                      </>
                    }
                  />
                </div>
              ))}
            </Form.Group>
          </Form>
          <Button
            onClick={() => {
              if (!loginLoader) handleValidation();
            }}
            className="reg-btn br-radius mt-2"
            to="/"
          >
            {loginLoader ? (
              <PulseLoader size={11} color={colorWhite} />
            ) : (
              " Create account"
            )}
          </Button>
          <div className="spacer-20"></div>
          <p className="m-0">
            I already have an account?{" "}
            <Button
              className="simple-link purple"
              onClick={() => LoginButtonClick()}
            >
              Sign-In here
            </Button>
          </p>
          <div className="spacer-30"></div>
          <div className="bottom-line">
            {/* {Promotioncode?.name && (
              <p>
                Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                {Promotioncode?.bonus}% <span>bonus!</span>
              </p>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={CreateAcoountWithEmail}
        centered
        onHide={() => setCreateAcoountWithEmail(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel sign-up">
          <Button
            className="close-btn"
            onClick={() => setCreateAcoountWithEmail(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Save Your Account With Email</h3>
          <ul className="text-list">
            <li>
              <p>Current Stage</p>
              <p>
                <b className="notranslate">{CurrentStage?.level}</b>
              </p>
            </li>
            <li>
              <p>Current Price</p>
              <p>
                <b className="notranslate"> {CurrentStage?.price_of_token} </b>{" "}
                USDT
              </p>
            </li>
            <li>
              <p>Tokens Sold</p>
              <p>
                <b className="notranslate">{percentage}</b> %
              </p>
            </li>
            {Promocode && (
              <li>
                <p>Using Referral</p>
                <p>
                  <b className="notranslate">{Promocode}</b>
                </p>
              </li>
            )}
          </ul>
          <Form autoComplete="off">
            <Row>
              <Col xl="4" lg="4" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={signUpData.name}
                    onChange={(e) => {
                      setSignUpData((prev) => ({
                        ...prev, // Correcting the typo here
                        name: e.target.value, // Correcting the typo here
                      }));
                    }}
                    type="text"
                    autoComplete="off"
                    className="input-name"
                    placeholder="Enter Your name"
                  />
                  <p className="color-red"> {signUpDataError.nameError} </p>
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={signUpData.email}
                    onChange={(e) => {
                      setSignUpData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                    type="email"
                    name="Email-Signup"
                    id="Email-Signup"
                    className="input-email "
                    placeholder="Enter Your Email"
                  />
                  <p className="color-red"> {signUpDataError.emailError} </p>
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="12">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone number </Form.Label>
                  <div className="log-i-cntnr">
                    <PhoneInput
                      defaultCountry={CurrentCountry?.toLocaleLowerCase()}
                      value={phone}
                      inputProps={{
                        autocomplete: "false",
                      }}
                      onChange={(inputValue, country) => {
                        setDialedCode(country.country.dialCode);
                        // console.log(inputValue, country)
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          phoneNumber: inputValue, // Correcting the typo here
                        }));
                        setPhone(inputValue);
                      }}
                    />
                    {signUpData.phoneNumber === `+${dialedCode}` ? (
                      <></>
                    ) : (
                      <>
                        {!isValid && (
                          <div style={{ color: colorRed }}>
                            Phone is not valid
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Password</Form.Label>
                  <div className="log-i-cntnr">
                    <Form.Control
                      autoFocus={false}
                      value={signUpData.password}
                      onChange={(e) => {
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          password: e.target.value, // Correcting the typo here
                        }));
                      }}
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      name="Password-Signup"
                      id="Password-Signup"
                      className="input-password"
                      placeholder="Enter your password"
                    />
                    {!showPassword ? (
                      <FaEyeSlash
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <FaEye
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </div>
                  <p className="color-red"> {signUpDataError.passwordError} </p>
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Repeat Password</Form.Label>
                  <div className="log-i-cntnr">
                    <Form.Control
                      value={signUpData.repeatPassword}
                      onChange={(e) => {
                        setSignUpData((prev) => ({
                          ...prev, // Correcting the typo here
                          repeatPassword: e.target.value, // Correcting the typo here
                        }));
                      }}
                      name="Repeat-Signup"
                      id="Repeat-Signup"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-Email"
                      className="input-password"
                      placeholder="Repeat your password"
                    />
                    {!showPassword ? (
                      <FaEyeSlash
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <FaEye
                        className="FaEye"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                    {/* className="log-i-cntnr" */}
                  </div>
                  <p className="color-red">
                    {" "}
                    {signUpDataError.repeatPasswordError}{" "}
                  </p>
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Label>Select token ( Optional )</Form.Label>

                <Form.Select
                  onChange={(e) => {
                    SetSelectedCurrency(
                      options.find((item) => item.value === e.target.value)
                    );
                  }}
                  className="form-control token-select mb-4"
                  aria-label="Default select example"
                >
                  <option>-- Select Token --</option>
                  {options.map((item, index) => {
                    return (
                      <option value={item.value}>
                        {item.label.toUpperCase()}
                      </option>
                    );
                  })}
                </Form.Select>

                <div className="NavSelect">
                  {/* 
                  <Select
                    // className="form-control token-select"
                    value={SelectedCurrency}
                    onChange={(selectedOption) => SetSelectedCurrency(selectedOption)}
                    options={options}
                    styles={customStyles}
                    placeholder="Select Currency"
                    isSearchable
                  /> */}
                </div>
              </Col>
              <Col xl="6" lg="6" md="6">
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Purchase amount ( Optional ) </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!selectedCurrency}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only numbers from 0 to 9 and an empty string (for backspace)
                      if (NumberRegex.test(inputValue)) {
                        SetSelectedvalue(inputValue);
                      }
                    }}
                    onKeyPress={(e) => {
                      // Allow only numbers from 0 to 9 and backspace
                      const keyCode = e.keyCode || e.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                        e.preventDefault();
                      }
                    }}
                    autocomplete="off"
                    className="input-dollar"
                    placeholder="Enter purchase amount"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="mb-2 mt-2"
              controlId="exampleForm.ControlInput1"
            >
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type={type}
                    checked={receiveEmail}
                    onChange={() => {
                      setReceiveEmail(!receiveEmail);
                    }}
                    id="recieve"
                    label={`I agree to receive email notifications from AlgoTech with order information, special offers, and updates.`}
                  />
                </div>
              ))}
            </Form.Group>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              {["checkbox"].map((type) => (
                <div key={`default-${type}`}>
                  <Form.Check
                    type={type}
                    checked={privacySelected}
                    onChange={() => {
                      setPrivacySelected(!privacySelected);
                    }}
                    id="Agree"
                    label={
                      <>
                        I agree to the{" "}
                        <Link
                          to="/TermsConditions"
                          target="_blank" // This opens the link in a new tab/window
                          rel="noopener noreferrer" // Recommended for security reasons
                          className="simple-link purple"
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          target="_blank" // This opens the link in a new tab/window
                          rel="noopener noreferrer" // Recommended for security reasons
                          to="/PrivacyPolicy"
                          className="simple-link purple"
                        >
                          Privacy Policy
                        </Link>
                      </>
                    }
                  />
                </div>
              ))}
            </Form.Group>
          </Form>
          <Button
            onClick={() => {
              if (!loginLoader) handleValidation(true);
            }}
            className="reg-btn br-radius mt-2"
            to="/"
          >
            {loginLoader ? (
              <PulseLoader size={11} color={colorWhite} />
            ) : (
              " Create account"
            )}
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={PasswordModal}
        centered
        onHide={() => setPasswordModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setPasswordModal(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <p style={{ textAlign: "center" }}>
            OTP has sent to your Registered Email
          </p>
          <div className="otpinput">
            <OTPInput
              value={PasswordOTP}
              onChange={setPasswordOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
          </div>
          <div className="text-center">
            <a
              className="text-center"
              onClick={() => LoginFun()}
              style={{ cursor: "pointer" }}
            >
              {loginLoader ? (
                <PulseLoader size={11} color={colorWhite} />
              ) : (
                " Resend OTP"
              )}
            </a>
            <br />
            <Button
              onClick={() => {
                Check_OTP_OnLogin();
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              {FactorLoader ? (
                <PulseLoader size={11} color="white" />
              ) : (
                "  Validate OTP"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Sign Up Modal Ends Here */}
    </>
  );
}
export default NavBar;
