import React from "react";
import icontether from "../Assets/img/Icons/coins (2).png";

import minilogo from "../Assets/img/Icons/icon-logo.png";
import iconetherium from "../Assets/img/Icons/icon-etherium.png";
import chart from "../Assets/img/chart.png";
function Tokenomics() {
  return (
    <>
      <h2>
        <img src={minilogo} alt="Logo" /> $ALGT <span>Tokenomics</span>
      </h2>
      <div className="spacer-30"></div>
      <img src={chart} alt="Chart" />
      <div className="spacer-20"></div>
      <div className="dark-layer">
        <ul className="crypto-supply-list">
          <li>
            <h6>Total Token Supply</h6>
            <h3>
              <img src={minilogo} alt="Logo" /> 350,000,000
            </h3>
          </li>
          <li>
            <h6>Token network</h6>
            <h3>
              <img src={iconetherium} alt="Etherium" /> ERC-20
            </h3>
          </li>
          <li>
            <h6>Estimated Launch Price</h6>
            <h3>
              <img src={icontether} alt="Tether" /> 0.15
            </h3>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Tokenomics;
