import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Table } from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import ChatButton from "../../Components/ChatButton";
import { getUserReferralHistory } from "../../Api";
import UserContest from "../../ContextAPI/UserContest";
import moment from "moment";
import { ReferralLink } from "../../Constants";
import { CopyToClipboardButton } from "../../Constants/utils";
function Referral({ setShowFooter, setShowNavBar }) {
  // Hide the footer when the Login component mounts
  const {  UserDetail } = useContext(UserContest);
 
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [ReferralData,setReferralData  ] = useState([])


  useEffect(()=>{
    if(UserDetail?.id)
    getUserReferralHistory(UserDetail?.id).then((data)=>{
  // console.log(data.data)
  setReferralData(data.data)

    }).catch(()=>{

    })
  },[UserDetail])
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="blue-post">
                  <h3 className="purple-color">Referral</h3>
                  <div className="spacer-20"></div>
                  <h4 className="font-weight-bolder">
                    Algotech.ai Referral Program
                  </h4>
                  <p>
                    The algotech Referral Program is easy and provides users
                    with an extra incentive to share our project with others.
                    Invite a friend or two and receive a portion of their
                    investment in algotech tokens for an extra bonus to your
                    current or newly earned position. Maybe you are an
                    influencer who wants to take your earnings to the next
                    level. Either way, the algotech Referral Program has
                    something for everyone. Currently, the bonus provided is 10%
                    of the investment in algotech tokens from anyone who uses
                    your referral link. The referred individual will also
                    receive an extra 10% of their investment in algotech tokens.
                    Talk about a win-win situation!
                  </p>
                  <div className="spacer-20"></div>
                  <h4 className="font-weight-bolder">What's a referral link?</h4>
                  <p>
                    Referral links are a link to buy.algotech.ai with a special
                    code on the end that makes your link unique. For example,
                    your referral link may look like this:
                    https://buy.algotech.io/invite?ref=YOUR_REFERRAL_LINK
                  </p>
                  <div className="spacer-20"></div>
                  <h4 className="font-weight-bolder">
                    How do I earn a referral bonus?
                  </h4>
                  <ul className="font-qs whitecolor">
                    <li>To earn a referral bonus:</li>
                    <li>
                      1. Go to Settings in your algotech.ai account or visit the
                      'My Profile' on algotech.ai to get your referral link.
                    </li>
                    <li>
                      2. Copy and share your referral link in an email, text
                      message, or through your favorite messaging app.
                    </li>
                    <li>
                      3. Your invitee signs up for a algotech.ai account after
                      opening your referral link.
                    </li>
                    <li>
                      4. Your invitee completes their account set-up and
                      initiates a minimum contribution within the presale phase.
                    </li>
                    <li>
                      Once your invitee completes the minimum buy for your
                      listed offer, you'll both receive the bonus amount listed
                      when your invitee signs up.
                    </li>
                  </ul>
                  <div className="spacer-20"></div>
                  <h4 className="font-weight-bolder">
                    When will I receive my referral bonus?
                  </h4>
                  <ul className="dot-list margin-less">
                    <li>
                      In order to receive the referral bonus, keep in mind that:
                    </li>
                    <li>
                      Your friend must not have an existing algotech account.
                    </li>
                    <li>
                      Referral bonuses are added on as soon as a contribution
                      has been confirmed; payment confirmations can take one to
                      three business days.
                    </li>
                    <li>
                      Buys can take up to four business days to complete. So, it
                      can take some time for payments to go out, and they may
                      not arrive immediately.
                    </li>
                  </ul>
                  <div className="spacer-20"></div>

                  <h4 className="font-weight-bolder">
                    When will I receive my referral bonus?
                  </h4>
                  <ul className="dot-list margin-less">
                    <li>
                      Here are a few possible reasons why you haven't received a
                      bonus:
                    </li>
                    <li>
                      The invitee must sign up and create a new algotech account
                      using your unique referral link. Referrals are not
                      captured if the referred person signs up using the
                      algotech.ai app instead of the unique referral link.
                    </li>
                    <li>
                      Referral bonuses are only paid if the invitee verifies
                      their account and completes a confirmed buy of the minimum
                      amount or more.
                    </li>
                    <li>
                      We do not pay out referral bonuses on duplicate or false
                      accounts; duplicate or shared financials will cause
                      disqualification.
                    </li>
                  </ul>
                  <div className="spacer-20"></div>
                  <h4 className="font-weight-bolder">Terms And Conditions</h4>
                  <p className="whitecolor">
                    Promoting your referral code through paid advertising based
                    on 'algotech' or algotech-related keywords is prohibited.
                    This includes, but is not limited to, bidding on or running
                    ads on search-based keywords that include 'algotech.ai' or
                    variations thereon. Using 'algotech’-branded terms in your
                    ad copy is not appropriate. Referrers who engage in such
                    activities will have their referral codes deactivated. All
                    referrals resulting from such activities are ineligible for
                    referral payments. Unfortunately, we can't guarantee pay out
                    on every account you refer due to the limitations mentioned
                    above and the risk of fraud. Still, we make every reasonable
                    effort to do so. algotech.ai reserves the right to change
                    the terms of the referral program at any time due to
                    changing market conditions, risk of fraud, or other reasons.
                    By participating in the referral program, you acknowledge
                    that receiving pay outs is not guaranteed by algotech
                  </p>
                  <div className="spacer-20"></div>
                  <Row>
                    <Col xl="8" lg="8" md="10" sm="12">
                      <h4 className="font-weight-bolder">Referral URL</h4>
                      <div className="d-flex referal-pnl">
                        <p className="m-0 notranslate">
                        {ReferralLink}{UserDetail?.referral_link}{" "}
                        </p>
                        <Link  onClick={()=>{
                      CopyToClipboardButton(ReferralLink+UserDetail?.referral_link,'Link Copied Successfully')
                    }} to={void(0)} className="ml-2">
                          <img src={iconcopy} alt="Icon Copy" />
                        </Link>
                      </div>
                      <p>
                        Use above link to refer your friend and get referral
                        bonus.
                      </p>
                      <div className="spacer-40"></div>
                      <h4 className="font-weight-bolder">Referral Lists</h4>
                      <div className="table-container">
                        <div className="table-container-inner">
                          <Table striped className="text-center h-b-less">
                            <thead>
                              <tr>
                                <th>User Name</th>
                                <th>Earn Token</th>
                                <th>Register Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                ReferralData?.map((item)=>{
                                  return(
                                    <tr>
                                    <td>{item?.email}</td>
                                    <td> {item?.amount??'-'}</td>
                                    <td>  {moment(item?.created_at).format("DD MMM, YYYY ")} </td>
                                  </tr>

                                  )

                                })
                              }
                           
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {
                        ReferralData?.length == 0 
                        &&    
                          <p className="redcolor font-weight-medium">
                        No one join yet!
                      </p>
                      }
                 
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
    </>
  );
}
export default Referral;
