import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Form,
  Modal,
  PageItem,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import icontransaction from "../../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../../Assets/img/admin-icons/icon-eye.png";
import iconi from "../../Assets/img/admin-icons/icon-i.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import iconusdc from "../../Assets/img/admin-icons/icon-udsc.png";
import qrcode from "../../Assets/img/q-r-code.png";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import Paginationnav from "../../Components/Paginationnav";
import ChatButton from "../../Components/ChatButton";
import { CreatePayment, UpdateTransaction, getAllTransactions, searchTransaction } from "../../Api";
import moment from 'moment'
import UserContest from "../../ContextAPI/UserContest";

import { QRCode } from "react-qrcode-logo";
import CountdownTimer from "../../Components/ExpireyDate";
import { BounceLoader, PulseLoader } from 'react-spinners'
import { CopyToClipboardButton } from "../../Constants/utils";
import { CircleLoader } from 'react-spinners'
import { CommasRegex, NumberRegex, TextInputRegex } from "../../Constants";
import { colorWhite } from "../../Constants";
function Transactions({ setShowFooter, setShowNavBar }) {


  const [RejecteddetailsShow, setRejecteddetailsShow] = useState(false);

  const [makepaymentShow, setmakepaymentShow] = useState(false);
  const [sellShow, setsellShow] = useState(false);
  const [transactions, setTransactions] = useState([])
  const [selectedtransaction, setSelectedtransaction] = useState({})
  const { UserDetail,CurrentStage } = useContext(UserContest);
  const [searchText, setSearchText] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(3)
  const [toAddress, setToAddress] = useState()
  const [paymentLoader, setpaymentLoader] = useState(false)
  const [ExpireyPayment, setExpireyPayment] = useState()
  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setendIndex] = useState()
  const [AmountToSend, setAmountToSend] = useState()
  const [loader, setLoader] = useState(true)
  const [sorting, setsorting] = useState({
    amount: false,
    crypto: false,
    token: false,
  })
  const order ={
     asc :'asc',
     desc :'desc',

  }
  const sortOrder =
    sorting.amount === order.asc || sorting.amount === order.desc
      ? sorting.amount + 'amount'
      : sorting.crypto === order.asc || sorting.crypto === order.desc
        ? sorting.crypto + 'crypto'
        : sorting.token === order.asc || sorting.token === order.desc
          ? sorting.token + 'token'
          : 'all';

  useEffect(() => {
    setCurrentPage(1)


  }, [searchText, itemsPerPage])

  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);

  useEffect(() => {
    setLoader(true)
    getAllTransactions(UserDetail?.id, searchText, currentPage, itemsPerPage ?? 1, sortOrder).then((data) => {
      // console.log(data.data);
      setLoader(false)
      setTransactions(data.data)
      setTotalItems(data.totalItems)

    }).catch(() => {

    })

  }, [searchText, UserDetail, itemsPerPage, sorting])


  const searchtransactionByHash = (tnHash) => {
    searchTransaction(tnHash, UserDetail?.id).then((item) => {
      // console.log(item.data[0])
      setSelectedtransaction(item.data[0])


    }).catch(() => {

    })
  }
  const ReCreatePayment = (item) => {
    setpaymentLoader(true)
    CreatePayment({
      amount: item.amount_in_dollars,
      selectedCurrency: item.selected_crypto,
      transaction_number: item.transaction_number,
      description: btoa( JSON.stringify({ stage_id:CurrentStage?.id, email:UserDetail?.email,
        created_from_referral: UserDetail?.created_from_referral ,
        referral_used: UserDetail?.referral_used
      }))
    }).then((createdPayment) => {
      setToAddress(createdPayment.pay_address)
      setExpireyPayment(createdPayment.expiration_estimate_date)
      setAmountToSend(createdPayment.pay_amount)


      let updateTransaction = {
        AmountToSend : createdPayment.pay_amount,
        expiry_date: createdPayment.expiration_estimate_date,
        payment_id: createdPayment.payment_id,
        toAddress: createdPayment.pay_address,
        transaction_number: item.transaction_number,
        payment_created_date: createdPayment.created_at
      }
      UpdateTransaction(updateTransaction).then(() => {
        setpaymentLoader(false)
      }).catch(() => {

      })



    }).catch(() => {

    })
  }
  const OnPageChange = (page) => {
    setCurrentPage(page)
    setLoader(true)
    getAllTransactions(UserDetail?.id, searchText, page, itemsPerPage ?? 1, sortOrder).then((data) => {
      // console.log(data.data);
      setLoader(false)
      setTransactions(data.data)

    }).catch(() => {

    })


  }



  useEffect(() => {
    let start = (currentPage - 1) * itemsPerPage ?? 1
    setStartIndex((currentPage - 1) * itemsPerPage ?? 1)
    setendIndex((parseInt(start) + parseInt(itemsPerPage ?? 1)) > parseInt(totalItems) ? (parseInt(start) + parseInt(itemsPerPage ?? 1)) - 1 : (parseInt(start) + parseInt(itemsPerPage ?? 1)))
    // console.log(parseInt(start) + parseInt(itemsPerPage))
  }, [currentPage, searchText, itemsPerPage])

  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="blue-post">
                  <h4 className="purple-color font-weight-bolder">
                    This table shows all types and status of transactions
                  </h4>
                  <div className="spacer-30"></div>
                  <div className="flex-div-md">
                    <div className="d-flex align-items-center">
                      <p className="m-0 font-weight-bold">Show</p>
                      <Form.Control
                        // console
                        className="font-qs bg-white px-4 py-2 ml-2 mr-2 border-round dark-color"
                        style={{ width: '70px' }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Allow only numbers from 0 to 9 and an empty string (for backspace)
                          if (NumberRegex.test(inputValue)) {
                            setItemsPerPage(inputValue)
                          }
                        }}
                        onKeyPress={(e) => {
                          // Allow only numbers from 0 to 9 and backspace
                          const keyCode = e.keyCode || e.which;
                          const keyValue = String.fromCharCode(keyCode);
                          if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                            e.preventDefault();
                          }
                        }}
                        value={itemsPerPage}
                        maxLength={2}
                        type="text"
                        autoComplete="new-saarch"
                      />


                      <p className="m-0 font-weight-bold">entries</p>
                    </div>

                    <div className="d-flex align-items-center mmt-3">
                      <p className="m-0 font-weight-bold">Search:</p>
                      <div className="search-pnl">
                        <Button>
                          <i className="fa fa-search"></i>
                        </Button>
                        <Form.Control
                          // console
                          onChange={(e) => {
                            const inputVal = e.target.value;
                            if (TextInputRegex.test(inputVal) || inputVal === "") {
                              setSearchText(e.target.value)
                            }
                          }}
 
                          value={searchText}
                          type="text"
                          autoComplete="new-saarch"
                          placeholder="Enter Search Request"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="spacer-20"></div>
                  <div className="table-container">
                    <div className="table-container-inner">
                      <Table striped className="transaction-table big">
                        <thead>
                          <tr>
                            <th>
                              {/* <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "} */}
                              Transaction ID
                            </th>
                            <th onClick={() => {
                              setsorting((prev) => ({
                                ...prev,
                                amount: false,
                                crypto: false,
                                token: prev.token === false || prev.token === 'desc' ? 'asc' : 'desc',

                              }));
                            }}>
                              <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"

                              />{" "}
                              Tokens
                            </th>
                            <th onClick={() => {
                              setsorting((prev) => ({
                                ...prev,
                                amount: false,
                                crypto: prev.crypto === false || prev.crypto === 'desc' ? 'asc' : 'desc',
                                token: false,
                              }));
                            }}>
                              <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "}
                              Amount in Cryptocurrency
                            </th>
                            <th onClick={() => {
                              setsorting((prev) => ({
                                ...prev,
                                amount: prev.amount === false || prev.amount === 'desc' ? 'asc' : 'desc',
                                crypto: false,
                                token: false,
                              }));
                            }}>
                              <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "}
                              Amount in US Dollars
                            </th>
                            <th>
                              {/* <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "} */}
                              To Wallet Address
                            </th>
                           
                            <th>
                              {/* <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "} */}
                              Type
                            </th>
                            <th>
                              {/* <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "} */}
                              Status
                            </th>
                            <th>
                              {/* <img
                                className="mr-1"
                                src={icontransaction}
                                alt="Arrows"
                              />{" "} */}
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <>
                               <tr>
                                  <td colSpan={8}>
                                    <div className=" loader-circle">
                                    <CircleLoader color={colorWhite} size={42} />
                                    </div>
                                  </td>
                                </tr>
                            
                            </>
                          ) : (
                            <>
                              {transactions?.map((item, index) => {
                                return (

                                  <tr>
                                    <td>
                                      <p className="m-0 f-18">{item?.transaction_number}</p>
                                      <span className="purple-color ">
                                        { moment.utc(item?.created_at).local().format("DD MMM, YYYY hh:mm A z")}
                                      </span>
                                    </td>
                                    <td>
                                      <p className="m-0 f-18">+{ parseFloat(item?.token_purchased ).toFixed(2)?.toString().replace(CommasRegex, ',')   }</p>
                                      <span className="purple-color ">$ALGT</span>
                                    </td>
                                    <td>
                                      <p className="m-0 f-18">{item?.amountafterfee??0}</p>
                                      <span className="purple-color ">
                                        {item?.selected_crypto.toUpperCase()}{" "}
                                      </span>
                                    </td>
                                    <td>
                                      <p className="m-0 f-18">{item?.amount_in_dollars}</p>
                                      <span className="purple-color ">
                                        USD{" "}
                                        {
                                          item?.type === 'Purchase' &&
                                          <span className="tooltip-span">
                                          <img src={iconi} alt="Icon i" />
                                          <span>1 ALGT = ${item?.tokenpriceatpurchase} </span>
                                        </span>
                                        }
                                  
                                      </span>
                                    </td>
                                    <td className="color">
                                      <p className="m-0 f-18">
                                        {
                                          !item?.to_wallet_address ?
                                            'Create Payment' :
                                            item?.to_wallet_address === "Created By Admin" ?
                                            item?.to_wallet_address
                                            :
                                            <>


                                              {
                                                item?.type === 'Bonus' && 'For Transaction Number '
                                              }

                                              {`${item?.to_wallet_address?.slice(0, 4)}...${item?.to_wallet_address?.slice(-4)}`}
                                            </>
                                        }

                                      </p>
                                      <span className="purple-color ">
                                      {/* { moment.utc(item?.created_at).local().format("DD MMM, YYYY hh:mm A z")} */}
                                     
                                        {item?.payment_created_date && moment(item?.payment_created_date).format("DD MMM, YYYY hh:mm A")}
                                      </span>
                                    </td>
                                    <td className="greencolor">{item?.type}</td>
                                    <td className="greencolor">{item?.status}</td>
                                    <td>

                                      <Button
                                        disabled={(item?.type !== 'Purchase' || item?.status !== 'Pending')}
                                        className="simple-link purple"
                                        onClick={() => {

                                          if (item?.type === 'Bonus')
                                            searchtransactionByHash(item?.to_wallet_address)
                                          else
                                            setSelectedtransaction(item)
                                          setRejecteddetailsShow(true)

                                        }
                                        }
                                      >
                                        <img
                                          className="mr-1"
                                          src={iconwallet}
                                          alt="Arrows"
                                        />{" "}
                                        Pay
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              })}
                              {transactions?.length === 0 && (
                                <tr>
                                  <td colSpan={8}>
                                    <div className="center-text-allign">
                                      No Transaction Found
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>



                      </Table>
                    </div>
                  </div>
                  <div className="spacer-10"></div>
                  <div className="flex-div-sm align-items-center">
                    <p className="m-0">
                      <b className="notranslate">Showing {startIndex === 0 ? 1 : startIndex} to {endIndex} of {totalItems} entries</b>
                    </p>
                    <Paginationnav
                      currentPage={currentPage}
                      totalItems={totalItems}
                      itemsPerPage={(parseInt(itemsPerPage ?? 1) === 0 || !parseInt(itemsPerPage ?? 1)) ? 1 : parseInt(itemsPerPage ?? 1)}
                      onPageChange={OnPageChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Wallet Address Modal Starts Here */}
      <Modal
        size="lg"
        show={makepaymentShow}
        centered
        onHide={() => setmakepaymentShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setmakepaymentShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Transaction Details</h3>
          <div className="spacer-10"></div>
          <span className="reject-span letter-spacing-1">rejected</span>
          <div className="spacer-20"></div>
          <p className="m-0">
            The order no. <span className="color">INQTXN040149</span> was placed
            on 15 Dec, 2023 03:22 PM.
          </p>
          <p className="redcolor">
            Sorry! Your order has been <b className="notranslate">canceled</b> due to payment.
          </p>
          <div className="spacer-30"></div>
          <p className="yellow-color">
            <b className="notranslate">Token Details</b>
          </p>
          <div className="table-container">
            <div className="table-container-inner">
              <Table striped className="td-brder">
                <tbody>
                  <tr>
                    <td>
                      <p className="m-0">Types</p>
                    </td>
                    <td>
                      <p className="m-0">Purchase</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Token of Stage</p>
                    </td>
                    <td>
                      <p className="m-0">Stage 6</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Token Amount (T)</p>
                    </td>
                    <td>
                      <p className="m-0">12987 $ALGT</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Bonus Token (B)</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">1948 $ALGT</p>
                        <p>(0 + 0)</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Total Token</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">14935 $ALGT</p>
                        <p>(T+B)</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Total Payment</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">0 USDT</p>
                        <p>250 USD</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <p className="redcolor m-0">
            The transaction was canceled by Administrator at 16 Dec, 2023 04:15
            AM.
          </p>
        </Modal.Body>
      </Modal>
      {/* Wallet Address Modal Ends Here */}

      {/* Make Payment Modal Starts Here */}
      <Modal
        size="lg"
        show={RejecteddetailsShow}
        centered
        onHide={() => setRejecteddetailsShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setRejecteddetailsShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <Row>
            <Col xl="8" lg="8" md="12">
              <h3>Make your payment</h3>
              <div className="spacer-10"></div>
              <h5 className="whitecolor font-qs">
                Your Order <span className="color">#{selectedtransaction?.transaction_number}</span> waiting
                for payment.
              </h5>
              <div className="spacer-10"></div>
              <hr />
            
              {
                (  selectedtransaction?.amountafterfee || AmountToSend )  &&
                <>
                  <p className="m-0">
                <b className="notranslate">Amount After Fee</b>
              </p>

              <h4 className="font-weight-bold font-qs">

                {selectedtransaction?.amountafterfee??AmountToSend } <span className="font-weight-normal">{selectedtransaction?.selected_crypto?.toUpperCase()}</span>

              </h4>
                </>

              }
            
              <div className="spacer-20"></div>

              {
                (selectedtransaction?.to_wallet_address || toAddress) &&
                <>
                  <p className="m-0">
                    <b className="notranslate">To this address</b>
                  </p>
                  <h5 className="whitecolor font-weight-normal font-qs addressTo">
                    {selectedtransaction?.to_wallet_address ?? toAddress}
                  </h5>
                  <Link className="simple-link purple"
                    onClick={() => CopyToClipboardButton(selectedtransaction?.to_wallet_address, 'Address Copied Successfully')}
                  >
                    <img src={iconcopy} alt="Copy" /> Copy Address
                  </Link>
                </>
              }
              {
                (!selectedtransaction?.to_wallet_address) &&
                <>
                  {
                    paymentLoader ?
                      <PulseLoader size={11} color={colorWhite} />
                      :
                      <>
                        {
                          !toAddress &&
                          <Link
                            onClick={() => {
                              ReCreatePayment(selectedtransaction)
                            }}
                            to={void (0)}
                            className="simple-link purple">
                            Create Payment
                          </Link>
                        }
                      </>
                  }

                </>


              }



              <div className="spacer-10"></div>
              <hr />
            </Col>

            <Col xl="4" lg="4" md="12" className="text-center">
              {/* {
                (selectedtransaction?.to_wallet_address || ExpireyPayment) &&
                <CountdownTimer Valid_Date={selectedtransaction?.expiry_date ?? ExpireyPayment} />
              } */}
              <div className="spacer-50"></div>
              {
                (selectedtransaction?.to_wallet_address || toAddress)
                &&
                <QRCode
                  value={selectedtransaction?.to_wallet_address ?? toAddress}
                  // logoImage={`${CurrencyImageUrl}${selectLogo?.logo_url}`}
                  padding={0}
                  size={200}
                  logoOpacity={1}
                  logoWidth={50}
                />
              }

              <div className="spacer-20"></div>
            </Col>
          </Row>

          <p className="yellow-color m-0 ">
            <div className="spacer-10"></div>
            <b className="notranslate">You Get:</b>
          </p>
          <h3 className="font-qs notranslate">
            <span>
             { parseFloat(selectedtransaction?.token_purchased).toFixed(2)?.toString().replace(CommasRegex, ',') ?? 0
            }
              <span className="font-weight-normal ml-2 f-26">
                $ALGT <img src={iconlogo} alt="ALGo Token" />
              </span>
            </span>
            <span className="yellow-color mx-3">≈</span>
            <span>
              {selectedtransaction?.amountafterfee}
              <span className="font-weight-normal ml-2 f-26">
                {selectedtransaction?.selected_crypto?.toUpperCase()} <img src={iconusdc} alt="USDC Token" />
              </span>
            </span>
          </h3>
          <hr />
          <Row>
            <Col xl="7" lg="10" md="12" sm="12">
              <div className="flex-div">
                <p className="m-0">Tokens Ordered:</p>
                <p className="m-0 purple-color">
             
                  <b className="notranslate">    { parseFloat(selectedtransaction?.token_purchased).toFixed(2)?.toString().replace(CommasRegex, ',') ?? 0} $ALGT</b>
                </p>
              </div>
              <div className="flex-div">
                <p className="m-0">Purchase Bonus:</p>
                <p className="m-0 purple-color">
               
                  <b className="notranslate"> {parseFloat( selectedtransaction?.bonus_token).toFixed(2)?.toString().replace(CommasRegex, ',') ?? 0 } $ALGT</b>
                </p>
              </div>
              <div className="flex-div">
                <p className="m-0">Referral Bonus:</p>
                <p className="m-0 purple-color">
                  <b className="notranslate">{selectedtransaction?.referralbonus??0} $ALGT</b>
                </p>
              </div>
              <div className="flex-div">
                <p className="m-0">Promo Bonus:</p>
                <p className="m-0 purple-color">
                  <b className="notranslate">{selectedtransaction?.promobonus??0} $ALGT</b>
                </p>
              </div>
              
              <div className="spacer-5"></div>
            </Col>
          </Row>
          <hr />
        </Modal.Body>
      </Modal>
      {/* make Payment Modal Ends Here */}

      {/* Sell Modal Starts Here */}
      <Modal
        size="lg"
        show={sellShow}
        centered
        onHide={() => setsellShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setsellShow(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <div className="text-center">
            <h3 className="m-0">Your Request Has Been Submited!</h3>
          </div>
        </Modal.Body>
      </Modal>
      {/* Sell Modal Ends Here */}
    </>
  );
}
export default Transactions;
