import { useEffect, useState } from "react";
import sanityClient from "../Api/sanity-client";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  PortableText,
} from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";

const BlogDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setLoading] = useState(true); // Adding loading state

  const builder = imageUrlBuilder(sanityClient);
  function urlFor(source) {
    return builder.image(source).url(); // Ensure this returns a string
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post' && slug.current == '${slug}']{
        title,
        slug,
        imgAlt,
        metaTitle,
        metaDescription,
        author->{
        name
        },
        category->{
          name
        },
        publishedAt,
        mainImage{
            asset->{
            _id,
            url
          }
        },
        body,
    }`
      )
      .then((data) => {
        setPost(data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [slug]);

  const ImageComponent = ({ value }) => (
    <img
      src={urlFor(value.asset)}
      width={800}
      height={400}
      alt={value.alt || "Blog Image"}
    />
  );

  const CTAComponent = {
    types: {
      callToAction: ({ value, isInline }) =>
        isInline ? (
          <a href={value.url}>{value.text}</a>
        ) : (
          <Button>{value.text}</Button>
        ),
    },
  };

  if (isLoading) {
    return (
      <section
        className="giveaway-pnl container-fluid"
        style={{
          minHeight: "100vh",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "40px",
        }}
      >
        Loading blog...
      </section>
    );
  }

  if (!post) {
    return (
      <section
        className="giveaway-pnl container-fluid"
        style={{
          minHeight: "100vh",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "40px",
        }}
      >
        404: Blog not found
      </section>
    );
  }

  const components = {
    types: {
      image: ImageComponent,
    },
    callToAction: CTAComponent,
    block: {
      h1: ({ children }) => <h1 style={{ fontSize: '2.25rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h1>,
      h2: ({ children }) => <h2 style={{ fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h2>,
      h3: ({ children }) => <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h3>,
      h4: ({ children }) => <h4 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h4>,
      h5: ({ children }) => <h5 style={{ fontSize: '1.125rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h5>,
      h6: ({ children }) => <h6 style={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: '1rem' }}>{children}</h6>,
      normal: ({ children }) => <p style={{ marginBottom: '1rem' }}>{children}</p>,
      blockquote: ({ children }) => <blockquote style={{ borderLeft: '4px solid gray', paddingLeft: '1rem', marginBottom: '1rem', fontStyle: 'italic' }}>{children}</blockquote>,
    },
    hardBreak: ({ value }) => <br />
  };
  

  console.log(post);

  return (
    <>
        <Helmet>
            <title>{post.metaTitle ? post.metaTitle : "Blog"} | Algo Tech</title>
            <meta
                name="description"
                content={`${
                    post.metaDescription ? post.metaDescription : "Blog"
                } | Algo Tech`}
            />
            <meta property="og:title" content={post.metaTitle ? post.metaTitle : "Blog"} />
            <meta
                property="og:description"
                content={`${
                    post.metaDescription ? post.metaDescription : "Blog"
                } | Algo Tech`}
            />
            <meta property="og:image" content={post.metaImage ? post.metaImage : "/feature.png"} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
        </Helmet>


        <section className="giveaway-pnl container-fluid">
        <Container className="blog-post-detail">
          <Row className="justify-content-md-center">
            <Col md={8}>
              <Image
                src={post?.mainImage?.asset?.url}
                alt={post.imgAlt ?? "Blog Image"}
                fluid
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
              <Card
                className="shadow-lg p-3 mb-5 rounded"
                style={{
                  backgroundColor: "rgba(106, 13, 173, 0.2)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Card.Header>
                  <Card.Title
                    style={{
                      fontSize: "44px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {post.title}
                  </Card.Title>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(to right, rgb(158, 142, 244) 0%, rgb(47, 24, 168) 98%)",
                      borderRadius: "6px",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {post.category.name}
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: "italic",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "darkgray",
                    }}
                  >
                    <div>
                      <strong>Published on:</strong>{" "}
                      {new Date(post.publishedAt).toLocaleDateString()}
                    </div>
                    <div>by {post.author.name}</div>
                  </Card.Text>
                </Card.Header>
              </Card>

              <div className="mt-3 text-left">
                <PortableText value={post.body} components={components} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogDetail;
