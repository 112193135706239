import React from "react";
function Roadmaplist() {
  return (
    <>
      <ul className="roadmap-list">
        <li>
          <div className="roadmap-post">
            <div className="heading">
              <h5>Phase 1</h5>
              <h4>Platform Enhancement</h4>
            </div>
            <ul className="dot-list">
              <li>
                Focus on improving the user interface and user experience
                (UI/UX) to provide a seamless and intuitive trading environment.
              </li>
              <li>
                Implement additional order types and advanced trading
                functionalities to cater to a wider range of trading strategies.
              </li>
              <li>
                Enhance the performance and speed of trade execution to ensure
                optimal user experience, even during peak trading periods
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="roadmap-post">
            <div className="heading">
              <h5>Phase 2</h5>
              <h4>Expanded Asset Coverage</h4>
            </div>
            <ul className="dot-list">
              <li>
                Introduce support for a broader range of cryptocurrencies and
                digital assets to offer users more trading opportunities.
              </li>
              <li>
                Collaborate with reputable exchanges to expand the list of
                available trading pairs, allowing users to access a diverse
                range of markets.
              </li>
              <li>
                Explore opportunities to integrate with decentralized finance
                (DeFi) protocols, enabling users to participate in decentralized
                trading and yield farming.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="roadmap-post">
            <div className="heading">
              <h5>Phase 3</h5>
              <h4>Advanced Trading Tools and Analytics</h4>
            </div>
            <ul className="dot-list">
              <li>
                Integrate advanced trading tools and indicators to empower users
                with enhanced analytical capabilities.
              </li>
              <li>
                Provide comprehensive charting functionalities and technical
                analysis tools to aid traders in making informed trading
                decisions.
              </li>
              <li>
                Develop customizable trading bots and algorithmic trading
                strategies to cater to individual trading preferences and
                objectives.
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="roadmap-post">
            <div className="heading">
              <h5>Phase 4</h5>
              <h4>Social Trading and Community Features</h4>
            </div>
            <ul className="dot-list">
              <li>
                Introduce social trading features that allow users to share and
                follow successful trading strategies within the Algotech
                community.
              </li>
              <li>
                Implement social sentiment analysis tools to provide insights
                into market trends and sentiment based on user-generated
                content..
              </li>
              <li>
                Foster a vibrant and collaborative community where users can
                interact, share knowledge, and learn from each other.
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
export default Roadmaplist;
