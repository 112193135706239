import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Container, Col, Form, Button, Modal } from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import iconi from "../../Assets/img/admin-icons/icon-i-w.png";
import iconip from "../../Assets/img/admin-icons/icon-i.png";
import iconaddress from "../../Assets/img/admin-icons/icon-address.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import {
  UpdateProfileApi,
  WalletaddressChanage,
  WalletaddressRequest,
  getBonusTokenOfUser,
  getPurchaseTokenOfUser,
  getReferralTokenOfUser,
  getTotalTokenOfUser,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import {
  CopyToClipboardButton,
  validateEthereumAddress,
} from "../../Constants/utils";
import { ReferralLink, colorRed } from "../../Constants";
import OtpInput from 'react-otp-input';
function MyToken({ setShowFooter, setShowNavBar }) {

  const [totalToken, setTotalToken] = useState();
  const [referralToken, setreferralToken] = useState();
  const [bonusToken, setbonusToken] = useState();
  const [purchaseToken, setPurchaseToken] = useState();
  const [wallerAddress, setWalletAddsress] = useState();
  const navigate = useNavigate();
  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);
  const [walletaddressShow, setwalletaddressShow] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false)
  const [OTP,setOTP] = useState()

  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  useEffect(() => {
    getTotalTokenOfUser(UserDetail?.id)
      .then((data) => {
        setTotalToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getPurchaseTokenOfUser(UserDetail?.id)
      .then((data) => {
        setPurchaseToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getBonusTokenOfUser(UserDetail?.id)
      .then((data) => {
        setbonusToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getReferralTokenOfUser(UserDetail?.id)
      .then((data) => {
        setreferralToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
  }, [UserDetail]);
  const UpdateWalletAddress = () => {
    UserDetail.wallet_address = wallerAddress;
    UpdateProfileApi(UserDetail)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if(localStorage.getItem('JwtToken'))
            {
          
            localStorage.clear()
            }
        
            navigate("/");
          });
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {});
  };


  const Walletaddresschanage = ()=>{
    WalletaddressChanage(UserDetail?.id).then(()=>{
      Notifications('success',"OTP has sent to your Mail")
  
    }).catch(()=>{

    })
   }
   const Walletaddressrequest = ()=>{
    const data = {
      user_id : UserDetail?.id,
      otp : OTP ,
      wallet_address : wallerAddress
    }
    WalletaddressRequest(data).then(()=>{
      getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem('JwtToken')) {

              localStorage.clear()
            }
            navigate("/");
          });
          setShowOTP(false)
          setOTP('')
          setwalletaddressShow(false)
        Notifications("success", "Wallet Added Successfully");

    }).catch(()=>{
      // Notifications("success", "Entered OTP is incorrect");
    })
   }

  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <div className="flex-contr lg">
                <div className="blue-post w-7">
                  <h4 className="font-weight-bolder purple-color">
                    My Wallet Address
                  </h4>
                  <p>
                    Add or update your Ethereum wallet address to receive your
                    $ALGT tokens.
                  </p>
                  <h6 className="text-uppercase">Receiving Wallet</h6>
                  <div className="d-flex">
                    {UserDetail?.wallet_address === "null" ||
                    UserDetail?.wallet_address === null ? (
                      <Button
                        className="simple-link purple font-qs"
                        onClick={() => setwalletaddressShow(true)}
                      >
                        Add
                      </Button>
                    ) : (
                      <>
                        <p
                          onClick={() =>
                            CopyToClipboardButton(
                              UserDetail?.wallet_address,
                              "Address Copied Successfully"
                            )
                          }
                          className="color m-0 cursor"
                        >{`${UserDetail?.wallet_address?.slice(
                          0,
                          5
                        )}...${UserDetail?.wallet_address?.slice(-5)}`}</p>

                        <span className="tooltip-span ml-2 mr-2">
                          <img src={iconi} alt="Icon i" />
                          <span>{UserDetail?.wallet_address}</span>
                        </span>
                        {/* </div> */}
                        <Button
                          className="simple-link purple font-qs"
                          onClick={() => {
                            setwalletaddressShow(true);
                            setWalletAddsress("");
                          }}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="blue-post w-5">
                  <h4 className="font-weight-bolder purple-color">
                    Refer a Friend
                  </h4>
                  <p>Refer a friend and earn $ALGT tokens.</p>
                  <h6 className="text-uppercase">My referral link:</h6>
                  <p className="purple purple-color notranslate">
                    {ReferralLink}
                    {UserDetail?.referral_link}{" "}
                    <Link
                      onClick={() => {
                        CopyToClipboardButton(
                          ReferralLink + UserDetail?.referral_link,
                          "Link Copied Successfully"
                        );
                      }}
                    >
                      <img src={iconcopy} alt="Copy icon" />
                    </Link>
                  </p>
                  <div className="spacer-10"></div>
                </div>
              </div>
              <div className="flex-contr lg">
                <div className="blue-post w-8">
                  <h4 className="font-weight-bolder purple-color">
                    My $ALGT Token
                  </h4>
                  <div className="spacer-20"></div>
                  <Row>
                    <Col xl="5" lg="6" md="12">
                      <h6>Total Token Amount</h6>
                      <div className="d-flex">
                        <h3 className="font-qs font-weight-bold yellow-color">
                          {totalToken ?? 0}
                        </h3>
                        <h4 className="font-weight-medium ml-3">
                          <img
                            className="mr-1"
                            src={iconlogo}
                            alt="Icon Logo"
                          />{" "}
                          $ALGT
                        </h4>
                      </div>
                      <p>
                        Equivalent to:{" "}
                        <b className="mx-2">
                          {CurrentStage?.price_of_token * totalToken ?? 0}
                        </b>{" "}
                        <b className="notranslate">USD</b>
                      </p>
                      <div className="spacer-20"></div>
                    </Col>
                    <Col xl="7" lg="6" md="12">
                      <ul className="my-token-list">
                        <li>
                          <p>Total Token Amount</p>
                          <p>
                            <span> {totalToken ?? 0} </span>
                            $ALGT
                          </p>
                        </li>
                        <li>
                          <p>Purchased Token</p>
                          <p>
                            <span>{purchaseToken ?? 0}</span>
                            $ALGT
                          </p>
                        </li>
                        <li>
                          <p>Referral Token</p>
                          <p>
                            <span>{referralToken ?? 0}</span>
                            $ALGT
                          </p>
                        </li>
                        <li>
                          <p>Bonus Token</p>
                          <p>
                            <span>{bonusToken ?? 0}</span>
                            $ALGT
                          </p>
                        </li>
                      </ul>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <Link
                        className="reg-btn br-radius letter-spacing-1"
                        to="/PurchaseToken"
                      >
                        Buy more tokens
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="blue-post w-4">
                  <h4 className="font-weight-bolder purple-color">
                    $ALGT Token Statistics
                  </h4>
                  <div className="spacer-10"></div>

                  <h6>Total Token Amount</h6>
                  <div className="d-flex">
                    <h3 className="font-qs font-weight-bold yellow-color">
                      {totalToken ?? 0}
                    </h3>
                    <h4 className="font-weight-medium ml-3">
                      <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                      $ALGT
                    </h4>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Edit  Wallet Address Modal Starts Here */}
      <Modal
        size="lg"
        show={walletaddressShow}
        centered
        onHide={() => setwalletaddressShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setwalletaddressShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Wallet Address</h3>
          <p>
            To receive your <b className="purple-color">$ALGT</b> tokens, you
            will need to enter your Ethereum wallet address in the box below.
            You can claim your <b className="purple-color">$ALGT</b> tokens when
            the presale ends.
          </p>
          <div className="spacer-20"></div>

          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Your Address</Form.Label>
              <Form.Control
                type="text"
                value={wallerAddress}
                onChange={(e) => {
                  setWalletAddsress(e.target.value);
                }}
                autoComplete="new-email"
                className="input-email"
                placeholder="Enter Your Address"
              />
              {wallerAddress && !validateEthereumAddress(wallerAddress) && (
                <p style={{ color: "red" }}>Please enter a valid Address</p>
              )}
            </Form.Group>
          </Form>
          <div className="d-flex align-items-start mb-2">
            <img className="mr-2" src={iconaddress} alt="Icon wallet" />
            <p className="m-0">
              Address should be <b className="purple-color">ERC-20</b>{" "}
              compliant.
            </p>
          </div>
          <div className="d-flex align-items-start">
            <img className="mr-2" src={iconip} alt="Icon i" />
            <p>
              Do NOT use your exchange wallet address or a wallet for which you
              do not have a private key. If you do, you will not receive your
              Tokens and could lose your funds.
            </p>
          </div>
          <div className="spacer-30"></div>
          <div className="text-center">
            <Button
              disabled={!validateEthereumAddress(wallerAddress)}
              onClick={() => {
                Walletaddressrequest()
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setShowOTP(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <p style={{textAlign:'center' }}>
             OTP has sent to your Registered Email
            </p>
          <div className="otpinput">
          <OtpInput
            value={OTP}
            onChange={setOTP}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          <br />
          </div>
          <div className="text-center">
          <a className="text-center" onClick={()=>Walletaddresschanage()} style={{ cursor:'pointer'}}>Resend OTP</a>
          <br />
            <Button
              onClick={() => {
                Walletaddressrequest()
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Wallet Address Modal Ends Here */}
    </>
  );
}
export default MyToken;
