import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Ailogo from "../Assets/img/who-we-are.png";
import videocover from "../Assets/img/video-cover.png";
import playicon from "../Assets/img/Icons/icon-video.png";
import { PresaleButton, PresalesButton } from "./Buttons";
import { useAccount, useDisconnect } from 'wagmi'

import { videoUrl } from "../Constants";
function Whoweare({ setSignupShow }) {
  const { address, isConnecting, isDisconnected } = useAccount()
  return (
    <>
      <Row >
        <Container className="zindex">
          <Row onClick={() => {


            setSignupShow(true)
          }
          }>
            <Col xl="4" lg="4" md="6" sm="12" className="table-web-view">
              <img src={Ailogo} alt="Ai" />
            </Col>
            <Col xl="8" lg="8" md="12" sm="12">
              <h5 className="text-uppercase letter-spacing">Who We Are</h5>
              <h2>
                Building <span>cutting-edge technology</span> that meets{" "}
                <span>intelligent trading strategies,</span> empowering traders
                to excel in the world of financial markets
              </h2>
              <div className="spacer-30"></div>
              <p className="border-text">
                At Algotech, we are a team of experienced professionals with a deep understanding of algorithmic trading and a passion for innovation. We combine cutting-edge technology, data analysis, and industry expertise to develop robust trading solutions that deliver exceptional results.
                Our team members are not just experts in their respective fields; they are also dedicated to ongoing learning and development. We actively engage in research, attend industry conferences, and collaborate with leading experts to stay ahead of market trends and emerging technologies. This commitment to continuous improvement ensures that our solutions remain cutting-edge and effective in today's fast-paced financial landscape. At Algotech, we are not just creating
                solutions for today; we are shaping the future of algorithmic trading with innovation, expertise, and a relentless drive for excellence.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
         
              xxl={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
            <div className="video-pnl" style={{height:'400px'}}>
  <iframe
    src={videoUrl}
    height='400'
    frameBorder='0' // Add this to remove iframe border if needed
    allowFullScreen // Add this to enable fullscreen mode
    title='video'
  ></iframe>
</div>
              <div className="spacer-50"></div>
            </Col>
          </Row>
          <Row onClick={() => {


            setSignupShow(true)
          }
          }>
            <Col xl="7" lg="7" md="10" sm="12">
              <h3>Our Vision</h3>
              <p className="border-text clr">
                We envision a world where traders can unlock their full
                potential and achieve consistent profitability through
                intelligent and automated trading strategies. By harnessing the
                power of technology and data-driven insights, we aim to level
                the playing field and empower traders to make informed decisions
                in real-time.
                At AlgoTech, our vision is ambitious yet tangible. We aspire to create a world where traders unlock their full potential and achieve consistent profitability through intelligent and automated trading strategies. Our cutting-edge technology and data-driven insights, help us in leveling the playing field in financial markets.

                Our goal is to democratize access to sophisticated trading tools and strategies, enabling all traders to make informed decisions in real-time and thrive in the ever-evolving landscape of global finance.
              </p>
            </Col>
          </Row>
          <Row onClick={() => {


            setSignupShow(true)
          }
          }>
            <Col xl="5" lg="5" md="12" className="table-web-view">
              <div className="spacer-30"></div>
              <ul className="btn-list text-center">
                <li>
                  <PresalesButton setSignupShow={setSignupShow} />
                </li>
                <li>
                  <Link
                    className="reg-btn br-radius trans"
                    onClick={() => {
                      setSignupShow(true)
                    }}
                    to={void (0)}
                  >
                    Learn More
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xl={{ span: 7, offset: 0 }} lg={{ span: 7, offset: 0 }} md={{ span: 10, offset: 1 }} sm="12">
              <h3>The commitment</h3>
              <p className="border-text clr">
                At Algotech, we are committed to the success and satisfaction of
                our users. We strive to provide exceptional customer support,
                regular platform updates, and educational resources to empower
                traders and enhance their trading experience.
                At AlgoTech, we are committed to the success and satisfaction of our users. Our unwavering dedication drives us to provide exceptional customer support, regular platform updates, and comprehensive educational resources. These initiatives are meticulously designed to empower traders, enhance their trading experience, and cultivate a thriving community of informed traders. Our dedication to ongoing improvement ensures that our users have the tools and knowledge they need to succeed in the dynamic world of financial markets. 

              </p>
            </Col>
            <Col xl="5" lg="5" md={{ span: 8, offset: 2 }} className="table-mobile-view">
              <div className="spacer-30"></div>
              <ul className="btn-list text-center">
                <li>
                  <PresaleButton setSignupShow={setSignupShow} />
                </li>
                <li>
                  <Link
                    className="reg-btn br-radius trans"
                    onClick={() => {
                      setSignupShow(true)
                    }}
                    to={void (0)}
                  >
                    Learn More
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
}
export default Whoweare;
