import React from "react";
import liveevent from "../Assets/img/live-event.png";
import iconalert from "../Assets/img/Icons/icon-alert.png";
import { PresaleButton } from "./Buttons";
function DubaiEvent({setSignupShow}) {
  return (
    <>
      <h3 className="color">
        <img src={iconalert} alt="Alert" /> Giveaway Alert
      </h3>
      <h2>
        <span>An All-Inclusive</span> Crypto Trading<br /> & Networking{" "}
        <span>Package</span>
      </h2>
      <img src={liveevent} alt="Live Event" />
      <p className="purple-color">
        Join the AlgoTech presale to qualify for winning the full crypto finance
        kit and crypto education.
      </p>
      <p>
        The lucky winner will get the latest iPhone, iPad, and Apple Watch for
        the ultimate trading experience. As a bonus, we'll also include two VIP
        tickets for the Blockchain Life 2024 event!
      </p>
      <div className="spacer-20"></div>
      <h4 className="font-weight-bolder">
        All deposits above{" "}
        <span className="yellow-color">$1 qualify for the selection.</span>
      </h4>
      <PresaleButton setSignupShow={setSignupShow}/>
    </>
  );
}
export default DubaiEvent;
