import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";

function Paginationnav({ currentPage, totalItems, itemsPerPage, onPageChange }) {
  const [activePage, setActivePage] = useState(currentPage);
  const [expandedPages, setExpandedPages] = useState([]);
  const expandInterval = 5; // Define expandInterval

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);
  useEffect(() => {
    setActivePage(1); // Reset active page to 1 when items per page changes
  }, [itemsPerPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePaginationClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setActivePage(page);
      onPageChange(page); // Call the callback function
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const expandThreshold = 10;

    if (totalPages > expandThreshold) {
      for (let page = 1; page <= totalPages; page++) {
        if (
          page === 1 ||
          page === totalPages ||
          page === activePage ||
          (page >= activePage - expandInterval &&
            page <= activePage + expandInterval) ||
          expandedPages.includes(page)
        ) {
          items.push(
            <Pagination.Item
              key={page}
              active={page === activePage}
              onClick={() => handlePaginationClick(page)}
            >
              {page}
            </Pagination.Item>
          );
        }
      
        if (
          page > expandThreshold &&
          page < activePage - expandInterval &&
          page > activePage + expandInterval
        ) {
          items.push(
            <Pagination.Ellipsis key={`ellipsis-${page}`} />
          );
        }
      }
    } else {
      for (let page = 1; page <= totalPages; page++) {
        items.push(
          <Pagination.Item
            key={page}
            active={page === activePage}
            onClick={() => handlePaginationClick(page)}
          >
            {page}
          </Pagination.Item>
        );
      }
    }
    return items;
  };

  const handleExpand = (page) => {
    setExpandedPages(
      [...Array(expandInterval).keys()].map(
        (i) => activePage - expandInterval + i
      )
    );
  };

  return (
    <>
      <Pagination>
        <Pagination.Prev
          onClick={() => handlePaginationClick(activePage - 1)}
          disabled={activePage === 1}
        />
        {renderPaginationItems()}
        <Pagination.Next
          onClick={() => handlePaginationClick(activePage + 1)}
          disabled={activePage === totalPages}
        />
      </Pagination>
    </>
  );
}

export default Paginationnav;
