import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Logo from "../Assets/img/Logo/footer-logo.png";
import git from "../Assets/img/Icons/icon-git.png";
import twitter from "../Assets/img/Icons/icon-twitter.png";
import Discord from "../Assets/img/Icons/icon-discord.png";
import {
  DiscordIcon,
  InstagramUrl,
  WhitePaperLink,
  teleGramUrl,
  twitterIcon,
} from "../Constants";

function Footer({
  scrollToFeatured,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  return (
    <>
      <footer className="container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Col>
              <Col xl="12" lg="12" md="12" sm="12">
                <ul className="footer-list">
                  <li>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to={"/"}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} onClick={() => scrollToFeatured()}>
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} onClick={() => RewardToFeatured()}>
                      Rewards
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => TokenToFeatured()} to={"/"}>
                      tokenomics
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => RoadToFeatured()} to={"/"}>
                      roadmap
                    </Link>
                  </li>
                  <li>
                    <Link to={WhitePaperLink} target="_blank">
                      whitepaper
                    </Link>
                  </li>
                  <li>
                    <Link to="/FAQs">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/Giveaway">Win-$250k</Link>
                  </li>
                  <li>
                    <Link to="/Blog">Blog</Link>
                  </li>
                </ul>
                <h6 className="letter-spacing">Follow us</h6>
                <ul className="footer-social-list">
                  <li>
                    <Link target="_blank" to={twitterIcon}>
                      <img src={twitter} alt="twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={DiscordIcon}>
                      <img src={Discord} alt="Discord" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={InstagramUrl}>
                      <i
                        className="fa fa-instagram fa-lg"
                        style={{ color: "rgb(204 203 41)" }}
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={teleGramUrl}>
                      <i
                        className="fa fa-telegram fa-lg"
                        style={{ color: "rgb(204 203 41)" }}
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
        <ul className="bottom-list">
          <li>
            <Link
              to="/TermsConditions"
              className={`${
                useLocation().pathname == "/TermsConditions" ? "active" : ""
              }`}
            >
              Terms & conditions
            </Link>
          </li>
          <li>
            <Link
              to="/PrivacyPolicy"
              className={`${
                useLocation().pathname == "/PrivacyPolicy" ? "active" : ""
              }`}
            >
              Privacy Policy
            </Link>
          </li>
          <li>© 2023 All Rights Reserved</li>
        </ul>
      </footer>
    </>
  );
}
export default Footer;
