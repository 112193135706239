import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import sanityClient from "../Api/sanity-client";

const Blogs = () => {
  const [blogsList, setBlogsList] = useState([]);
  const [blogsPerPage] = useState(9); // Adjust the number of blogs per page here
  const [blogsDisplayed, setBlogsDisplayed] = useState(blogsPerPage);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post'] {
          title,
          slug,
          imgAlt,
          author->{
            name
          },
          category->{
            name
          },
          publishedAt,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body
        }`
      )
      .then((data) => setBlogsList(data))
      .catch((error) => console.log(error));
  }, []);

  // Get current blogs
  const currentBlogs = blogsList?.slice(0, blogsDisplayed);

  // Load more blogs
  const loadMoreBlogs = () => {
    setBlogsDisplayed(blogsDisplayed + blogsPerPage);
  };

  return (
    <>
      <Helmet>
        <title>Blogs - Algo Tech</title>
        <meta name="description" content="Blogs - Algo Tech" />
      </Helmet>

      <section className="giveaway-pnl container-fluid">
        <Container>
          <div className="spacer-10"></div>
          <h4>
            Keep up with the{" "}
            <b style={{ color: "#DBDB02" }}>latest in tech and crypto</b>
          </h4>
          <div className="spacer-30"></div>
          {currentBlogs.length === 0 ? (
            <section
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px",
              }}
            >
              No blogs found
            </section>
          ) : (
            <>
              <Row xs={1} md={2} lg={3} style={{ rowGap: "30px" }}>
                {currentBlogs?.map((item, i) => (
                  <Col key={i}>
                    <Card className="blog-post-card">
                      <Card.Img
                        alt={item.imgAlt ?? "Blog Image"}
                        variant="top"
                        src={item?.mainImage?.asset?.url}
                        style={{
                          maxHeight: "200px",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                      />

                      <Card.Body>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            color: "#FFFFFF",
                            background:
                              "linear-gradient(to right, rgb(158, 142, 244) 0%, rgb(47, 24, 168) 98%)",
                            borderRadius: "6px",
                            display: "inline-block",
                            padding: "4px",
                          }}
                        >
                          {item.category.name}
                        </Card.Title>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            color: "#FFFFFF",
                          }}
                        >
                          {item.title}
                        </Card.Title>
                        <Card.Title
                        className="card-style-blog"
                        >
                          by {item.author.name} •{" "}
                          {new Date(item.publishedAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </Card.Title>
                        <div className="spacer-10"></div>
                        <Link
                          className="reg-btn br-radius"
                          to={`/Blog/${item.slug.current}`}
                
                         className="ard-style-blog2"
                        >
                          Continue Reading
                        </Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              {blogsList.length > blogsDisplayed && (
                <div style={{ textAlign: "center" }}>
                  <Button variant="primary" onClick={loadMoreBlogs}>
                    View More
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </section>
    </>
  );
};

export default Blogs;
