import React from "react";
import { Link } from "react-router-dom";
import featured1 from "../Assets/img/Featured/Featured-1.png";
import featured2 from "../Assets/img/Featured/Featured-2.png";
import featured3 from "../Assets/img/Featured/Featured-3.png";
import featured4 from "../Assets/img/Featured/Featured-4.png";
import featured5 from "../Assets/img/Featured/Featured-5.png";
import CommunityCollaboration from "../Assets/img/Featured/Community Collaboration.png";
import Comprehensive from "../Assets/img/Featured/Comprehensive Reporting and Analytics.png";
import Customizable from "../Assets/img/Featured/Customizable Trading Strategies.png";
import Integration from "../Assets/img/Featured/Integration with External Tools and APIs.png";
import Real from "../Assets/img/Featured/Real-Time Market Insight.png";

function FeaturedPost() {
  return (
    <>
      <ul className="featured-list">
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured1} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Wide Range</span> Of Algorithm Strategies
              </h3>
              <span></span>
              <p>
              AlgoTech's diverse algorithms provide tailored and reliable solutions, optimizing client success across various market conditions. Our commitment to innovation ensures transparency and seamless adaptation to varied data landscapes. Scalable algorithm support facilitates growth, while ethical AI practices minimize biases, fostering trust and confidence among our users. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured2} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Robust</span> Technical Infrastructure
              </h3>
              <span></span>
              <p>
              AlgoTech efficiently manages large trading volumes with swift execution and low latency. Our optimized infrastructure guarantees reliability and user-friendly experiences, making AlgoTech a powerful tool for traders seeking rapid and effective transactions in dynamic financial markets. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured3} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                Risk <span>Management </span>
              </h3>
              <span></span>
              <p>
              AlgoTech excels in mitigating financial losses and enhancing overall trading performance through advanced risk management strategies. Our algorithms analyze market conditions in real-time, identifying and responding to potential risks promptly. This proactive risk mitigation contributes to a more resilient and successful trading experience for our users. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured4} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Decentralized</span> and <span> Transparent</span>
              </h3>
              <span></span>
              <p>
              AlgoTech ensures transparent trading and immunity by offering clear transaction visibility. Our platform employs robust safeguards, protecting users from negative market impacts and providing a secure and transparent trading experience that builds trust and confidence.
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={featured5} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Machine Learning</span> and <span>AI</span>
              </h3>
              <span></span>
              <p>
              AlgoTech extensively analyzes historical and real-time market data using advanced machine learning and AI techniques. By processing vast databases, we identify patterns, trends, and opportunities that empower informed decision-making. This capability enhances our platform's adaptability to dynamic market conditions, ensuring that our users stay ahead of the curve in their trading strategies. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={Customizable} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Customizable </span> Trading <span>Strategies</span>
              </h3>
              <span></span>
              <p>
              AlgoTech offers the flexibility for users to customize and fine-tune their trading strategies according to their preferences and objectives. This customization empowers traders to tailor their approach based on market conditions and personal risk tolerance.
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={Real} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Real-Time </span> Market  <span>Insights</span>
              </h3>
              <span></span>
              <p>
              AlgoTech provides users with real-time market insights and analysis, including market trends, price movements, and sentiment analysis. These insights help traders make informed decisions and capitalize on emerging opportunities swiftly. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={Comprehensive} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Comprehensive Reporting  </span> and  <span>Analytics</span>
              </h3>
              <span></span>
              <p>
              AlgoTech offers comprehensive reporting and analytics tools, allowing users to track their trading performance, analyze past trades, and identify areas for improvement. These tools facilitate data-driven decision-making and enhance overall trading efficiency.
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={Integration} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Integration  with   </span> External Tools  <span>and APIs</span>
              </h3>
              <span></span>
              <p>
              AlgoTech seamlessly integrates with external trading tools, APIs, and data sources, providing users with access to a broader ecosystem of resources and functionalities. This integration enhances the platform's capabilities and expands the range of trading strategies available to users. 
              </p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/" className="featured-post">
            <div className="img-pnl">
              <img src={CommunityCollaboration} alt="Wide Range" />
            </div>
            <div className="txt-pnl">
              <h3>
                <span>Community  Collaboration </span>
              </h3>
              <span></span>
              <p>
              AlgoTech fosters a collaborative community where users can interact, share insights, and learn from each other. Through features like social trading and community forums, users can leverage collective knowledge and expertise to enhance their trading strategies and stay updated with market trends.
             </p>
            </div>
          </Link>
        </li>
      </ul>
    </>
  );
}
export default FeaturedPost;
