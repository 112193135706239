import React from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import bgimg from "../Assets/img/Bg/bg-6.png";
import { PresaleNowButton } from "./Buttons";
import { Link } from "react-router-dom";
function Joinpresalenow({ setSignupShow }) {
  return (
    <>
      <Container className="join-presale-now text-center" fluid>
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg})` }}
        ></div>
        <Row>
          <Container>
            <Row>
              <Col xl={{ span: 8, offset: 2 }}>
                <h2>
                  Unlock <span>Superior Trading Potential</span> with{" "}
                  <span>Cutting-Edge Algorithms</span>
                </h2>
                <>
                  <div className="mt-4 mb-1">
                    <Link onClick={() => { setSignupShow(true) }} className="reg-btn br-radius" to={void (0)}>
                      Join Presale NOW
                    </Link>
                   
                  </div>
                  <Link className="simple-link" to="/HowToBuy">
                    How to Join?
                  </Link>
                </>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Joinpresalenow;
