import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Container, Col, Form, Modal, Button } from "react-bootstrap";

import icontether from "../Assets/img/Icons/icon-teather.png";
import icontether1 from "../Assets/img/Icons/icon-teather-1.png";
import sold from "../Assets/img/sold.png";
import iconcheck from "../Assets/img/Icons/icon-check.png";
import iconcross from "../Assets/img/Icons/icon-cross.png";
import coincard from "../Assets/img/Icons/icon-card.png";
import coinusdc from "../Assets/img/Icons/icon-usdc.png";
import coinbusd from "../Assets/img/Icons/icon-busd.png";
import coinbnb from "../Assets/img/Icons/icon-bnb.png";
import coincrypto from "../Assets/img/Icons/icon-crypto.png";
import coineth from "../Assets/img/Icons/icon-eth.png";
import coinusdt from "../Assets/img/Icons/icon-usdt.png";
import Speed from "../Assets/img/Icons/icon-efficiancy.png";
import Consistency from "../Assets/img/Icons/icon-consistancy.png";
import data from "../Assets/img/Icons/icon-data-driven.png";
import Accessibility from "../Assets/img/Icons/icon-accesability.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserContest from "../ContextAPI/UserContest";
import { GraphTotal, getBonusTokenCountWithOutStage, getSumOfCurrencies, getTokenBonusCount, getTotalTokenOfwallet, getUserDetail } from "../Api";
import PurchaseTokenWithWallet from "../Pages/AdminPanel/PurchaseTokenWithWallet";
import icon1 from '../Assets/img/Icons/svg3409.png'
import icon2 from '../Assets/img/Icons/coins (1).png'
import icon3 from '../Assets/img/Icons/coins (2).png'
import icon4 from '../Assets/img/Icons/coins.png'
import icon5 from '../Assets/img/Icons/Frame.png'
import icon6 from '../Assets/img/Icons/icon.png'
import icon7 from '../Assets/img/Icons/Layer_1 (1).png'
import icon8 from '../Assets/img/Icons/Layer_1.png'
import Discordison from '../Assets/img/Icons/discord.png'
import { useAccount, useDisconnect } from 'wagmi'

import Discord from "../Assets/img/Icons/icon-discord.png";
import { BannerVideo, CommasRegex, DiscordIcon, InstagramUrl, teleGramUrl, twitterIcon } from "../Constants";


// import { Promocode } from "../ContextAPI/type";
function Header({ setSignupShow, SignupShow }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
  } = useContext(UserContest);
  const iconsArray = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8]

  const [SelectedChain, setSelectedChain] = useState()
  const [BonusToken,setBonusToken]=useState()
  const navigate = useNavigate();
  const [totalToken, settotalToken] = useState(0)
  const [saleGraph , setsaleGraph ]  = useState()
  const [bonusWithoutStage,setBonusWithOutSale] = useState()
  // const address =?
  const { address, isConnecting, isDisconnected } = useAccount()

  const { disconnect } = useDisconnect()
  const [percentage, setPercentage] = useState();
  const Network = {
    mainNetwork: 'mainNetwork',
    BUSD: 'BUSD',
    USDT: 'USDT',
    USDC: 'USDC',
  }
  useEffect(()=>{

    GraphTotal().then((data)=>{
      // console.log('GraphTotal',data.data.totalraised )
      setsaleGraph( data.data.totalraised  )
    }).catch(()=>{

    })
    window.scrollTo(0,0)

  },[])


  useEffect(() => {
    if(CurrentStage?.id)
    getTokenBonusCount(CurrentStage?.id).then((data)=>{
      // console.log('getTokenBonusCount',data.total_tokens_purchased)
      setBonusToken( parseInt( data.total_tokens_purchased) + 120000 )


    }).catch(()=>{

    })
    getBonusTokenCountWithOutStage().then((data)=>{
      setBonusWithOutSale( parseInt( data.total_tokens_purchased) + 120000 )
    }).catch(()=>{
    })
    getUserDetail()
      .then((detail) => {
        // console.log(detail ,'getUserDetail' )
        // navigate("/Dashboard");
        SetUserDetail(detail);
      })
      .catch(() => {
        if (localStorage.getItem('JwtToken')) {
          localStorage.clear();
        }
        // navigate('/')
      });

    let percentage = ( parseInt( saleGraph )  / parseInt( CurrentStage?.total_limit ) ) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)
    let remaining =  parseInt ( parseInt(CurrentStage?.total_limit) -
    (  (parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken)) > parseInt(CurrentStage?.total_limit)? parseInt(CurrentStage?.total_limit):(parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken))   ) )
    let sold =   parseInt(parseInt( TotalSaleInStage)  + parseInt( BonusToken)  ) 

    setPercentage( parseFloat(( sold / (remaining +   sold ) )  * 100  ).toFixed(2) ) ;

    // setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  
  }, [TotalSaleInStage, CurrentStage , saleGraph ,BonusToken ]);
  const DefaultChain = {
    ETH: 'ETH',
    BNB: 'BNB'
  }

  useEffect(() => {
    if (address )
      getTotalTokenOfwallet(address).then((totlatoke) => {

        settotalToken(totlatoke.data ?? 0)

      }).catch(() => {

      })

  }, [address])
  const [TotalRaised, setTotalRaised] = useState()

  useEffect(() => {
    let sum = 0;
    getSumOfCurrencies().then((res) => {
      setTotalRaised(res.data.totalraised )


    }).catch(() => {

    })

  }, [])
  const bgVideoRef = useRef(null);
  const videoSource = BannerVideo; // Replace with the actual path to your video file

  useEffect(() => {
    // Set the playback rate to 0.5 for half speed (adjust as needed)
    if (bgVideoRef.current) {
      bgVideoRef.current.playbackRate = 0.5;
    }
  }, []);

  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    // Set up an interval to change the icon every 2 seconds
    const intervalId = setInterval(() => {
      // Update the current icon index
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconsArray.length);
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on mount


  return (
    <>
      <header className="container-fluid">
        <div className="bg-layer">
          <div className="video-wrapper">
           <img alt='bg-layer__video' src={BannerVideo} className="bg-layer__video" />
  
          </div>
        </div>
        <Container className="zindex">
          <Row>
            <Col xl="6" lg="6" md="12" >
              <div onClick={() => {

                setSignupShow(true)

              }} >
                <h1>
                  Unlock <span >Superior Trading Potential</span> with Cutting-Edge
                  <span >  Algorithms </span>
                </h1>
                <h4 style={{  marginTop: '40px'  , fontSize:'20px'}} >
                  A decentralized algorithmic crypto trading platform to
                  revolutionize trading and investment with innovative
                  technologies and automation. AlgoTech is leading the way in revolutionizing trading and investment through its DeFi trading platform, algorithmic crypto trading, and innovative blockchain technologies.
                </h4>
              </div>
              <div

                className="hero_icons" >
                <Link target="_blank" to={twitterIcon}>
                  <i className="fa fa-twitter-square fa-2x" style={{ color: 'rgb(204 203 41)' }} aria-hidden="true"></i>
                </Link>
                <Link target="_blank" to={DiscordIcon}>
                  <img src={Discordison} style={{ width: '35px' }} alt="Discord" />
                </Link>
                <Link target="_blank" to={InstagramUrl}>
                  <i className="fa fa-instagram fa-2x" style={{ color: 'rgb(204 203 41)' }} aria-hidden="true"></i>
                </Link>
                <Link target="_blank" to={teleGramUrl}>
                  <i className="fa fa-telegram fa-2x" style={{ color: 'rgb(204 203 41)' }} aria-hidden="true"></i>
                </Link>

              </div>
              <div onClick={() => {

                setSignupShow(true)

              }}
                style={{ width: '100%', height: '100%' }}
              >

              </div>





            </Col>
            <Col xl="6" lg="6" md="12"


              style={!isDisconnected && !isConnecting ? { display: 'none' } : {}}
              className="text-center">
              <div className="stage-post"
                onClick={() => {

                  setSignupShow(true)

                }}
              >
                <div className="img-pnl">
                  <h4 className="mt-0 font-weight-bold yellow-color">
                    {CurrentStage?.level}
                  </h4>

                  <CircularProgressbar
                    value={percentage / 100}
                    maxValue={1}
                    text={`${percentage}%`}
                  />
                  {/* <img src={sold} alt="Data" /> */}
                </div>
                <div className="txt-pnl mt-4">
                  <div className="flex-div">
                    <p>Tokens remaining in stage:</p>
                    <span>
                      { parseFloat(parseInt ( parseInt(CurrentStage?.total_limit) -
    (  (parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken)) > parseInt(CurrentStage?.total_limit) ? parseInt(CurrentStage?.total_limit): (parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken))   ) ))?.toString().replace(CommasRegex, ',')}
                    </span>
                    {/* <span>
                     {
                     parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                     }
                        </span> */}
                  </div>
                  <div className="flex-div">
                    <p>$ALGT price in current stage:</p>
                    <span className="yellow-color">
                      {CurrentStage?.price_of_token}{" "}
                      <img src={icontether1} alt="tether" />
                    </span>
                  </div>
                  <div className="flex-div">
                    <p>Token price in the next stage:</p>
                    <span>{CurrentStage?.futuretokenprice}</span>
                  </div>
                  <hr />
                  <div className="flex-div">
                    <p>Tokens Sold In Total:</p>
                    <span>{(parseInt( OverAllSaleToken)  + parseInt( bonusWithoutStage)  - 118187802  )?.toString().replace(CommasRegex, ',') ?? 0}</span>
                  </div>
                  <div className="flex-div">
                    <p>Total Raised:</p>
                    <span>$ { ( ( ( parseInt( OverAllSaleToken)  + parseInt( bonusWithoutStage )   )  - 39952920  )  * 0.04 ).toFixed(3)?.toString().replace(CommasRegex, ',') ?? 0}</span>
             </div>
                </div>
              </div>
              <div className="apply-code-pnl">
                <div className="apply-code-pnl-inner">
                  <Form>
                    <div
                      className="input-cntnr"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        value={Promocode}
                        onChange={(e) =>
                          {
                            let temp =  e.target.value.split('ref=')
                          SetPromocode(temp[1] ? temp[1].split('&')[0] : e.target.value)
                        }
                        }
                        autoComplete="off"
                        type="text"
                        placeholder="Enter Referral CODE here"
                      />
                    </div>
                  </Form>
                  <a
                    onClick={() => {
                      if (Promocode) setSignupShow(true);
                    }}
                    className="reg-btn br-radius trans"
                    href={void 0}
                  >
                    Apply Code
                  </a>
                </div>
              </div>
              <div
                onClick={() => {

                  setSignupShow(true)

                }}
                className="btn-cntnr">

                <img
                  style={{ marginTop: '15px' }}
                  className="mr-3 "
                  src={iconsArray[currentIconIndex]}
                  alt={`Icon ${currentIconIndex + 1}`}
                />

                <div className="mt-4 mb-1">
                  <Link
                    className="reg-btn br-radius mt-4 mb-1"
                    onClick={() => setSignupShow(true)}
                    to={void (0)}
                  >
                    Join Presale
                  </Link>
                  <br />
                  <Link className="simple-link" to="/HowToBuy">
                    How to Join?
                  </Link>
                </div>
              </div>
            </Col>

            <Col
              xl="6"
              lg="6"
              md="12"
              style={!isDisconnected && !isConnecting ? { display: 'initial' } : {}}
              className="text-center displayNone"
            >
              <div className="stage-post">
                <div className="img-pnl">
                  <h4 className="mt-0 font-weight-bold yellow-color">
                    {CurrentStage?.level}
                  </h4>
                  <CircularProgressbar
                    value={percentage / 100}
                    maxValue={1}
                    text={`${percentage}%`}
                  />
                </div>
                <div className="txt-pnl mt-4">
                  <div className="flex-div">
                    <p>Tokens remaining in stage:</p>
                    <span>
                      { parseFloat(parseInt ( parseInt(CurrentStage?.total_limit) -
    (  (parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken)) > parseInt(CurrentStage?.total_limit)? parseInt(CurrentStage?.total_limit):(parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken))   ) ))?.toString().replace(CommasRegex, ',')}
                    </span>
                    {/* <span>
                      {
                          parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                      }
                    </span> */}
                  </div>
                  <div className="flex-div">
                    <p>$ALGT price in current stage:</p>
                    <span className="yellow-color">
                      {CurrentStage?.price_of_token}{" "}
                      <img src={icontether1} alt="tether" />
                    </span>
                  </div>
                  <div className="flex-div">
                    <p>Token price in the next stage:</p>
                    <span>{CurrentStage?.futuretokenprice}</span>
                  </div>
                  <hr />
                  <div className="flex-div">
                    <p>Tokens Sold In Total:</p>
                    <span>{( (parseInt( OverAllSaleToken)  + parseInt( BonusToken)    ) - 118187802   )?.toString().replace(CommasRegex, ',') ?? 0}</span>
                  </div>
                  <div className="flex-div">
                    <p>Total Raised:</p>
                    <span>$ { ( parseFloat( (parseInt( OverAllSaleToken)  + parseInt( BonusToken)  )    - 39952920 ) * 0.04   ).toFixed(3)?.toString().replace(CommasRegex, ',') ?? 0}</span>
             </div>
                </div>
              </div>
              {/* <div className="apply-code-pnl">
                <div className="flex-div">
                  <p className="m-0">
                    {" "}
                    <img src={iconcheck} alt="Icon Check" /> Promo Code{" "}
                    <span className="purple-color">“ALGT15”</span> has been
                    applied! Your bonus is 15%
                  </p>
                  <Link to="/">
                    <img src={iconcross} alt="icon cross" />
                  </Link>
                </div>
              </div> */}
              <div className="apply-code-pnl">
                <div className="flex-div">
                  <p>Connected Wallet</p>
                  <p className=" purple-color">
                    <b className="address-p">{address}</b>
                  </p>
                </div>
                <div className="flex-div">
                  <p>Your balance:</p>
                  <p>
                    <b className="purple-color">{(parseFloat(totalToken)?.toFixed(4) ?? 0) ?? 0}</b> Tokens{" "}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate('/walletTransactions/transaction')
                      }} to={void (0)}>Transactions history</a>
                  </p>
                </div>
                <hr></hr>
                <div className="text-center">
                  <Link to={void(0)} onClick={ async () => {
                     disconnect();
                    //  window.location.reload()

                  }
                  }>Disconnect</Link>
                </div>
              </div>
              <div className="text-center">
                <div className="spacer-20"></div>

                <div className="spacer-10"></div>
              </div>
              <ul className="coin-btn-list">
                <li>
                  <Link
                    to='/walletTransactions/ETH'
                    onClick={() => {

                      setSelectedChain('ETH')


                    }}
                    className="reg-btn br-radius" >
                    <img src={coineth} alt="ETH" /> ETH
                  </Link>
                </li>
                <li>
                  <Link to={`/walletTransactions/${Network.USDT}`} className="reg-btn br-radius"
                    onClick={() => {

                      setSelectedChain(Network.USDT)


                    }}

                  >
                    <img src={coinusdt} alt="USDT" /> USDT
                  </Link>
                </li>
                <li>
                  <Link to={`/walletTransactions/${Network.USDC}`} className="reg-btn br-radius"

                    onClick={() => {

                      setSelectedChain(Network.USDC)
                    }}
                  >
                    <img src={coinusdc} alt="USDC" /> USDC
                  </Link>
                </li>
                <li>
                  <Link to={`/walletTransactions/${Network.BUSD}`} className="reg-btn br-radius"
                    onClick={() => {


                      setSelectedChain(Network.BUSD)
                    }

                    }
                  >
                    <img src={coinbusd} alt="BUSD" /> BUSD
                  </Link>
                </li>
              </ul>
              <ul className="coin-btn-list three">
                <li>
                  <Link to='/walletTransactions/BNB' className="reg-btn br-radius "
                    onClick={() => {

                      setSelectedChain('BNB')
                    }}
                  >

                    <img src={coinbnb} alt="BNB" /> BNB
                  </Link>
                </li>
                <li>
                  <Link to={'/walletTransactions/OtherCurrency'} className="reg-btn br-radius"

                  >
                    <img src={coincrypto} alt="Crypto" /> other crypto
                  </Link>
                </li>
                <li>
                  <Link className="reg-btn br-radius" to="https://www.moonpay.com/buy" target="_blank">
                    <img src={coincard} alt="Card" /> card
                  </Link>
                </li>
              </ul>
              <Link className="simple-link" to="/HowToBuy">
                How to Join?
              </Link>
            </Col>
          </Row>
        </Container>
        <ul
          onClick={() => {

            setSignupShow(true)
          }}
          className="accessbility-list">
          <li>
            <div className="img-pnl">
              <img src={Speed} alt="Speed and Efficiency" />
            </div>
            <span>Speed and Efficiency</span>
          </li>
          <li>
            <div className="img-pnl">
              <img src={Consistency} alt="Consistency and Discipline" />
            </div>
            <span>Consistency and Discipline</span>
          </li>
          <li>
            <div className="img-pnl">
              <img src={data} alt="Data-driven Decision making" />
            </div>
            <span>Data-driven Decision making</span>
          </li>
          <li>
            <div className="img-pnl">
              <img src={Accessibility} alt="Scalability and Accessibility" />
            </div>
            <span>Scalability and Accessibility</span>
          </li>
        </ul>

      </header>
    </>
  );
}
export default Header;
