import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Joinpresalenow from "../Components/Joinpresalenow";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import roadmapbg from "../Assets/img/Bg/bg-5.png";
import Dubaieventbg from "../Assets/img/Bg/bg-4.png";
import whobg from "../Assets/img/Bg/bg-2.png";
import rewardbg from "../Assets/img/Bg/bg-3.png";
import Partner1 from "../Assets/img/Wallets/Trezor.png";
import Partner2 from "../Assets/img/Wallets/Etherium.png";
import Partner3 from "../Assets/img/Wallets/Uniswap.png";
import Partner4 from "../Assets/img/Wallets/U-Today.png";
import Partner5 from "../Assets/img/Wallets/MetaMask.png";
import Partner6 from "../Assets/img/Wallets/Cointelegraph.png";
import Partner7 from "../Assets/img/Wallets/Trust-Wallet.png";
import Roadmaplist from "../Components/Roadmaplist";
import Header from "../Components/Header";
import DubaiEvent from "../Components/DubaiEvent";
import Tokenomics from "../Components/Tokenomics";
import { PresaleButton, PresalesButton } from "../Components/Buttons";
import StrategiesPost from "../Components/StrategiesPost";
import Whoweare from "../Components/Whoweare";
import FeaturedPost from "../Components/FeaturedPost";
import FishList from "../Components/FishList";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { useAccount, useDisconnect } from "wagmi";
import RewardFishList from "../Components/RewardFishList";
function Home({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  featuredSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      {/* Header Starts Here */}
 
      <Header setSignupShow={setSignupShow} SignupShow={SignupShow} />
      {/* Header Starts Here */}

      {/* Partnership Starts Here */}
      <section
        onClick={() => {
          setSignupShow(true);
        }}
        className="container-fluid partnership-panel text-center"
      >
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <h3>Partnership</h3>
                <div className="spacer-30"></div>
                <ul className="partner-list">
                  <li>
                    <img src={Partner1} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner2} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner3} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner4} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner5} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner6} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner7} alt="Partner" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Partnership Ends Here */}

      {/* Who We Are Starts Here */}
      <section className="container-fluid who-we-are-pnl">
        <div
          onClick={() => {
            setSignupShow(true);
          }}
          className="bg-layer"
          style={{ backgroundImage: `url(${whobg})` }}
        ></div>
        <Whoweare setSignupShow={setSignupShow} />
      </section>
      {/* Who We Are Ends Here */}
      {/* Featured Panel Starts Here */}
      <section className="container-fluid text-center">
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
          ref={featuredSectionRef}
        >
          <Container>
            <Row>
              <Col
                xxl={{ span: 6, offset: 3 }}
                xl={{ span: 8, offset: 2 }}
                lg={{ span: 10, offset: 1 }}
              >
                <h5 className="text-uppercase letter-spacing">Key Features</h5>
                <h2>
                  Unlock <span>Superior Trading Potential</span> with{" "}
                  <span>Cutting-Edge Algorithms</span>
                </h2>
                <div className="spacer-30"></div>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                {/* Featured Post */}
                <FeaturedPost />
                {/* Featured Post */}
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Featured Panel Ends Here */}

      {/* Reward Panel Starts Here */}
      <section
        ref={RewardSectionRef}
        className="container-fluid reward-pnl text-center"
      >
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${rewardbg})` }}
        ></div>
        <Row>
          <Container className="zindex">
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col xl={{ span: 6, offset: 3 }}>
                <h5 className="text-uppercase letter-spacing">Reward Levels</h5>
                <h2>
                  <span>Earn a Share</span> of Our Investment
                </h2>
                <div className="spacer-30"></div>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                <RewardFishList />
              </Col>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                {/* <div
                  className="dark-layer"
                  onClick={() => {
                    setSignupShow(true);
                  }}
                >
                  <ul className="percentage-list">
                    <li>
                      <p>
        
                        Receives the Basic Rewards
                      </p>
                      <p>
              
                        Get 10% of the 30% performance fee
                      </p>
                    </li>
                    <li>
                      <p>
                      
                        Get 25% of the 30% performance fee
                      </p>
                      <p>
                     
                        Get 25% of the 30% performance fee
                      </p>
                    </li>
                  </ul>
                </div> */}
                <div className="spacer-40"></div>
                <Link className="reg-btn br-radius" to="/Rewards">
                  Get your new reward <span className="color">NOW</span>!
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Reward Panel Ends Here */}

      {/* Strategies Starts Here */}
      <section className="container-fluid text-center">
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
        >
          <Container>
            <Row>
              <Col
                xxl={{ span: 6, offset: 3 }}
                xl={{ span: 8, offset: 2 }}
                lg={{ span: 10, offset: 1 }}
              >
                <h5 className="text-uppercase letter-spacing">Strategies</h5>
                <h2>
                  Explore <span>cutting-edge trading strategies</span> suitable
                  for any market
                </h2>
                <div className="spacer-30"></div>
              </Col>
            </Row>
            <Row>
              {/*Strategies Post */}
              <StrategiesPost />
              {/*Strategies Post */}

              <Col xl="12" lg="12">
                <ul className="btn-list text-center justify-center">
                  <li>
                    <PresalesButton setSignupShow={setSignupShow} />
                  </li>
                  <li>
                    <Link
                      className="reg-btn br-radius trans"
                      onClick={() => {
                        setSignupShow(true);
                      }}
                      to={void 0}
                    >
                      Learn More
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Strategies Ends Here */}

      {/* Live Event In Dubai Starts Here */}
      <section
        onClick={() => {
          setSignupShow(true);
        }}
        className="container-fluid dubai-event text-center"
      >
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${Dubaieventbg})` }}
        ></div>
        <Row>
          <Container>
            <Row>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                <DubaiEvent setSignupShow={setSignupShow} />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Live Event In Dubai Ends Here */}

      {/* AlGt Tokenomics Starts Here */}
      <section
        onClick={() => {
          setSignupShow(true);
        }}
        ref={TokenSectionRef}
        className="container-fluid text-center"
      >
        <Row>
          <Container>
            <Row>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                <Tokenomics />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* AlGt Tokenomics Ends Here */}

      {/* Road Map Panel Starts Here */}
      <section
        onClick={() => {
          setSignupShow(true);
        }}
        ref={RoadSectionRef}
        className="container-fluid roadmap"
      >
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${roadmapbg})` }}
        ></div>
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" className="text-center">
                <h2>Roadmap</h2>
              </Col>
              <Col xl="12" lg="12" md="12">
                {/* Road Map */}
                <Roadmaplist />
                {/* Road Map */}
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Road Map Panel Ends Here */}

      {/* Frequently Question Panel Starts Here */}
      <section ref={FAQeSectionRef} className="container-fluid text-center">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" sm={{ span: 10, offset: 1 }}>
                <h5 className="text-uppercase letter-spacing">
                  What you need to know
                </h5>
                <h2>
                  <span>Frequently</span> with Asked Questions
                </h2>
              </Col>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                <Frequentlyquestion />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Frequently Question Panel Ends Here */}

      {/* Join Pre Sale Now Panel */}
      <Joinpresalenow setSignupShow={setSignupShow} />
      {/* Join Pre Sale Now Panel */}
    </>
  );
}
export default Home;
