import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Row, Container, Col, Button, Modal, Form } from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import sold from "../../Assets/img/sold.png";
import check from "../../Assets/img/admin-icons/icon-check.png";
import iconi from "../../Assets/img/admin-icons/icon-i-w.png";
import iconip from "../../Assets/img/admin-icons/icon-i.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import iconusd from "../../Assets/img/admin-icons/icon-tether.png";
import iconeth from "../../Assets/img/admin-icons/icon-etherium.png";
import iconbnb from "../../Assets/img/admin-icons/icon-bnb.png";
import iconaddress from "../../Assets/img/admin-icons/icon-address.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  GetSelectedCurrencyPrice,
  GraphTotal,
  UpdateProfileApi,
  WalletaddressChanage,
  WalletaddressRequest,
  getBonusTokenCountWithOutStage,
  getTokenBonusCount,
  getTotalTokenOfUser,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import {
  CopyToClipboardButton,
  validateEthereumAddress,
} from "../../Constants/utils";
import OtpInput from 'react-otp-input';

function Dashboard({ setShowFooter, setShowNavBar }) {
  const [walletaddressShow, setwalletaddressShow] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false)
  const [OTP,setOTP] = useState()
  const [editwalletaddressShow, seteditwalletaddressShow] = useState(false);
  const [percentage, setPercentage] = useState();
  const [BonusToken,setBonusToken]=useState()
  const [bonusWithoutStage,setBonusWithOutSale] = useState()
  const [userBalance, setUserBalance] = useState();
  const navigate = useNavigate();

  const [saleGraph , setsaleGraph ]  = useState()
  const [wallerAddress, setWalletAddsress] = useState();
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [BNBvalue, setBNBValue] = useState();

  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    Promotioncode,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);
  useEffect(()=>{
    if(CurrentStage?.id)
    getTokenBonusCount(CurrentStage?.id).then((data)=>{
      // console.log('getTokenBonusCount',data.total_tokens_purchased)
      setBonusToken( parseInt( data.total_tokens_purchased) + 120000)


    }).catch(()=>{

    })
    getBonusTokenCountWithOutStage().then((data)=>{
      setBonusWithOutSale( parseInt( data.total_tokens_purchased) + 120000 )
    }).catch(()=>{
    })

    GraphTotal().then((data)=>{
      setsaleGraph(data.data.totalraised)
    }).catch(()=>{

    })
  },[CurrentStage])
  useEffect(() => {
    let percentage = ( parseInt(  saleGraph )  / parseInt(   CurrentStage?.total_limit )) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)

    let remaining =  parseInt ( parseInt(CurrentStage?.total_limit) -
    (  (parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken)) > parseInt(CurrentStage?.total_limit)? parseInt(CurrentStage?.total_limit):(parseInt(TotalSaleInStage ?? 0)  + parseInt( BonusToken))   ) )
    let sold =   parseInt(parseInt( TotalSaleInStage)  + parseInt( BonusToken)  ) 

    setPercentage( parseFloat(( sold / (remaining +   sold ) )  * 100  ).toFixed(2) ) ;

  //  setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  
  }, [TotalSaleInStage, CurrentStage , saleGraph ,BonusToken ]);
  useEffect(() => {
    getTotalTokenOfUser(UserDetail?.id)
      .then((data) => {
        setUserBalance(data.data.total_tokens_purchased);
      })
      .catch(() => { });
    GetSelectedCurrencyPrice("bnbbsc")
      .then((price) => {
        // console.log(price,'price')
        setBNBValue(price);
      })
      .catch(() => { });
  }, [UserDetail]);

  const UpdateWalletAddress = () => {
    UserDetail.wallet_address = wallerAddress;
    UpdateProfileApi(UserDetail)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem('JwtToken')) {

              localStorage.clear()
            }
            navigate("/");
          });
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => { });
  };


   const Walletaddresschanage = ()=>{
    WalletaddressChanage(UserDetail?.id).then(()=>{
      Notifications('success',"OTP has sent to your Mail")
  
    }).catch(()=>{

    })
   }
   const Walletaddressrequest = ()=>{
    const data = {
      user_id : UserDetail?.id,
      otp : OTP ,
      wallet_address : wallerAddress
    }
    WalletaddressRequest(data).then(()=>{
      getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem('JwtToken')) {

              localStorage.clear()
            }
            navigate("/");
          });
          setShowOTP(false)
          setOTP('')
          setwalletaddressShow(false)
        Notifications("success", "Wallet Added Successfully");

    }).catch(()=>{
      // Notifications("success", "Entered OTP is incorrect");
    })
   }
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <div className="flex-contr lg">
                <div className="blue-post w-7 lg-f token-blnce-pnl flex-div-sm align-items-center">
                          
                    <div className="heading-pnl">
                    <h6 className="text-uppercase">
                      Token Balance
                      <span className="tooltip-span">
                        <img src={iconi} alt="Icon i" />
                        <span>Equivalent to ~  {CurrentStage?.price_of_token * userBalance ?? 0} USD</span>
                      </span>
                    </h6>
                    <div className="d-flex">
                      <h3 className="font-qs font-weight-bold yellow-color">
                        {userBalance ?? 0}
                      </h3>
                      <h4 className="font-weight-medium ml-3">
                        <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                        $ALGT
                      </h4>
                    </div>
                    <div className="top-line" >        
                                  {Promotioncode?.name && (
          <p>
            Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
            {Promotioncode?.bonus}% <span>bonus!</span>
          </p>
        )}
                 
            </div>
                  </div>
            

                  <div className="txt-pnl">
                    <h6>Balance in other currencies:</h6>
                    <div className="spacer-10"></div>
                    <div className="flex-div">
                      <h5 className="notranslate m-0 font-weight-bold purple-color font-qs">
                        0
                      </h5>
                      <h5 className="notranslate m-0 font-weight-medium whitecolor font-qs">
                        USD{" "}
                        <img
                          className="ml-1 img-sp"
                          src={iconusd}
                          alt="Icon Usd"
                        />{" "}
                      </h5>
                    </div>
                    <div className="spacer-5"></div>
                    <div className="flex-div">
                      <h5 className="notranslate m-0 font-weight-bold purple-color font-qs">
                        0
                      </h5>
                      <h5 className="notranslate m-0 font-weight-medium whitecolor font-qs">
                        ETH{" "}
                        <img
                          className="ml-1 img-sp"
                          src={iconeth}
                          alt="Icon Eth"
                        />{" "}
                      </h5>
                    </div>
                    <div className="spacer-5"></div>
                    <div className="flex-div">
                      <h5 className="notranslate m-0 font-weight-bold purple-color font-qs">
                        0
                      </h5>
                      <h5 className="notranslate m-0 font-weight-medium whitecolor font-qs">
                        BNB{" "}
                        <img
                          className="ml-1 img-sp"
                          src={iconbnb}
                          alt="Icon BNB"
                        />{" "}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="blue-post w-5 lg-f token-blnce-pnl flex-div-sm align-items-center">
                  <div className="stage-panel text-center">
                    <h4 className="mt-0 font-weight-bold yellow-color font-qs">
                      {CurrentStage?.level}
                    </h4>
                    <CircularProgressbar
                      value={percentage / 100}
                      maxValue={1}
                      text={`${percentage}%`}
                    />
                    {/* <img src={sold} alt="Data" /> */}
                  </div>

                  <div className="text-pnl">
                    <div className="flex-div">
                      <h5 className="notranslate m-0 font-weight-bold whitecolor font-qs">
                        1 $ALGT
                      </h5>
                      <h5 className="notranslate m-0 font-weight-bold purple-color font-qs">
                        =
                      </h5>
                      <h5 className="notranslate m-0 font-weight-bold whitecolor font-qs">
                        {CurrentStage?.price_of_token || ""} USD
                      </h5>
                    </div>
                    <div className="spacer-20"></div>
                    <div className="flex-div">
                      <h5 className="notranslate m-0 font-weight-bold whitecolor font-qs">
                        1 USD
                      </h5>
                      <h5 className="notranslate m-0 font-weight-bold purple-color font-qs">
                        =
                      </h5>
                      <h5 className="notranslate m-0 font-weight-bold whitecolor font-qs">
                        {parseFloat(BNBvalue??0).toFixed(4)  } BNB
                      </h5>
                    </div>
                    <div className="spacer-30"></div>
                    <Link
                      className="reg-btn br-radius letter-spacing-1"
                      to="/PurchaseToken"
                    >
                      BUY TOKEN NOW
                    </Link>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="flex-contr">
                <div className="blue-post w-5 lg-h">
                  <div className="spacer-10"></div>
                  <div className="flex-div">
                    <h6 className="m-0 text-uppercase">Account Status</h6>
                    <p className="m-0">
                      <img src={check} alt="Verified" /> Verified
                    </p>
                  </div>
                  <div className="spacer-10"></div>
                </div>
                <div className="blue-post  w-7 lg-h">
                  <div>
                    <h6 className="m-0 text-uppercase">Receiving Wallet</h6>
                    {UserDetail?.wallet_address === "null" ||
                      (UserDetail?.wallet_address === null && (
                        <Button
                          className="simple-link purple font-qs tooltip-span"
                          onClick={() => {setwalletaddressShow(true)
                         }
                          }
                        >
                          Add your wallet address
                        </Button>
                      ))}

                    <div className="d-flex notranslate">
                      {UserDetail?.wallet_address !== "null" &&
                        UserDetail?.wallet_address && (
                          <>
                            <p
                              onClick={() =>
                                CopyToClipboardButton(
                                  UserDetail?.wallet_address,
                                  "Address Copied Successfully"
                                )
                              }
                              className="color m-0 cursor"
                            >{`${UserDetail?.wallet_address?.slice(
                              0,
                              5
                            )}...${UserDetail?.wallet_address?.slice(-5)}`}</p>
                            <span className="tooltip-span ml-2 mr-2">
                              <img src={iconi} alt="Icon i" />
                             
                            </span>
                            <Button
                              className="simple-link purple font-qs"
                              onClick={() => setwalletaddressShow(true)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col xl={{ span: 8, offset: 2 }} className="text-center">
                <div className="spacer-20"></div>
                <h3>
                  A Decentralized algorithmic crypto trading platform to Revolutionize trading and investment with innovative technologies and automation
                </h3>
                <div className="spacer-50"></div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>

      {/* Wallet Address Modal Starts Here */}

      <Modal
        size="lg"
        show={walletaddressShow}
        centered
        onHide={() => setwalletaddressShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setwalletaddressShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h3>Wallet Address</h3>
          <p>
            To receive your <b className="purple-color">$ALGT</b> tokens, you
            will need to enter your Ethereum wallet address in the box below.
            You can claim your <b className="purple-color">$ALGT</b> tokens when
            the presale ends.
          </p>
          <div className="spacer-20"></div>

          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Your Address</Form.Label>
              <Form.Control
                type="text"
                value={wallerAddress}
                onChange={(e) => {
                  setWalletAddsress(e.target.value);
                }}
                autoComplete="new-email"
                className="input-email"
                placeholder="Enter Your Address"
              />
              {wallerAddress && !validateEthereumAddress(wallerAddress) && (
                <p style={{ color: "red" }}>Please enter a valid Address</p>
              )}
            </Form.Group>
          </Form>
          <div className="d-flex align-items-start mb-2">
            <img className="mr-2" src={iconaddress} alt="Icon wallet" />
            <p className="m-0">
              Address should be <b className="purple-color">ERC-20</b>{" "}
              compliant.
            </p>
          </div>
          <div className="d-flex align-items-start">
            <img className="mr-2" src={iconip} alt="Icon i" />
            <p>
              Do NOT use your exchange wallet address or a wallet for which you
              do not have a private key. If you do, you will not receive your
              Tokens and could lose your funds.
            </p>
          </div>
          <div className="spacer-30"></div>
          <div className="text-center">
            <Button
              disabled={!validateEthereumAddress(wallerAddress)}
              onClick={() => {
                Walletaddressrequest()
                //  setShowOTP(true)
                //  setOTP(false)
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setShowOTP(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <p style={{textAlign:'center' }}>
             OTP has sent to your Registered Email
            </p>
          <div className="otpinput">
          <OtpInput
            value={OTP}
            onChange={setOTP}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          <br />

          </div>

          <div className="text-center">
          <a className="text-center" onClick={()=>Walletaddresschanage()} style={{ cursor:'pointer'}}>Resend OTP</a>
          <br />
            <Button
              onClick={() => {
                Walletaddressrequest()
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
}
export default Dashboard;
