import React from "react";
import { Link } from "react-router-dom";
import SeaUrchins from "../Assets/img/Sea/Sea-Urchins.png";
import Dolphin from "../Assets/img/Sea/DOLPHIN.png";
import BARRACUDA from "../Assets/img/Sea/BARRACUDA.png";
import STINGRAY from "../Assets/img/Sea/STINGRAY.png";
import SEALION from "../Assets/img/Sea/SEA-LION.png";
import WHALE from "../Assets/img/Sea/BLUE-WHALE.png";
import MEGALODON from "../Assets/img/Sea/MEGALODON.png";
import THEKRAKEN from "../Assets/img/Sea/THE-KRAKEN.png";
function RewardFishList() {
  return (
    <>
      <ul className="fish-list reward">
        <li className="checked">
        
          <div className="img-pnl">
            <span className="check-span"> Level 1</span>
            <span className="lock-span"> Level 1</span>
            <div className="img">
              <img src={SeaUrchins} alt="Sea Urchins" />
            </div>
          </div>
          <div className="txt-pnl">
            <h6>Sea Urchins</h6>
            {/* <span className="cngrat-span">Congratulations</span> */}
            <p>Achieve at </p>
            <p>500,000</p>
            <br />
            <span className="brdr-span"></span>
            <br />
            <span>
              <b>1%</b>
            </span>
            <p>You receive of the companies profits</p>
          </div>
        </li>
        <li>
          <Link to="/">
            <div className="img-pnl">
              <span className="check-span"> Level 2</span>
              <span className="lock-span"> Level 2</span>
              <div className="img">
                <img src={Dolphin} alt="Dolphin" />
              </div>
            </div>
            <div className="txt-pnl">
              <h6>DOLPHIN</h6>
              <p>Achieve at </p>
              <p>1,000,000</p>
              <br />
            
              <span className="brdr-span"></span>
              <br />
              <span>
                <b>1.5%</b>
              </span>
           
   
              <p>You receive of the companies profits</p>
            </div>
          </Link>
        </li>
        <li>
          <div className="img-pnl">
            <span className="check-span"> Level 3</span>
            <span className="lock-span"> Level 3</span>
            <div className="img">
              <img src={BARRACUDA} alt="BARRACUDA" />
            </div>
          </div>
          <div className="txt-pnl">
            <h6>BARRACUDA</h6>
            <p>Achieve at </p>
            <p>2,000,000</p>
            <br />
            <span className="brdr-span"></span>
               <br />
            <span>
              <b>2%</b>
            </span>
            <p>You receive  of the companies profits</p>
          </div>
        </li>
        <li>
          <Link to="/">
            <div className="img-pnl">
              <span className="check-span"> Level 4</span>
              <span className="lock-span"> Level 4</span>
              <div className="img">
                <img src={STINGRAY} alt="STINGRAY" />
              </div>
            </div>
            <div className="txt-pnl">
              <h6>STINGRAY</h6>
              <p>Achieve at </p>
              <p>4,000,000</p>
              <br />
              <span className="brdr-span"></span>
                 <br />
              <span>
                <b>2.5%</b>
              </span>
              <p>You receive of the companies profits</p>
            </div>
          </Link>
        </li>
        <li>
          <div className="img-pnl">
            <span className="check-span"> Level 5</span>
            <span className="lock-span"> Level 5</span>
            <div className="img">
              <img src={SEALION} alt="SEA LION" />
            </div>
          </div>
          <div className="txt-pnl">
            <h6>SEA LION</h6>
            <p>Achieve at </p>
            <p>6,000,000</p>
            <br />
            <span className="brdr-span"></span>
               <br />
            <span>
              <b>3%</b>
            </span>
            <p> You receive of the companies profits</p>
          </div>
        </li>
        <li>
          <Link to="/">
            <div className="img-pnl">
              <span className="check-span"> Level 6</span>
              <span className="lock-span"> Level 6</span>
              <div className="img">
                <img src={WHALE} alt="WHALE" />
              </div>
            </div>
            <div className="txt-pnl">
              <h6>WHALE</h6>
              <p>Achieve at </p>
              <p>8,000,000</p>
              <br />
              <span className="brdr-span"></span>
                 <br />
              <span>
                <b>4%</b>
              </span>
              <p>You receive  of the companies profits</p>
            </div>
          </Link>
        </li>
        <li>
          <div className="img-pnl">
            <span className="check-span"> Level 7</span>
            <span className="lock-span"> Level 7</span>
            <div className="img">
              <img src={MEGALODON} alt="MEGALODON" />
            </div>
          </div>
          <div className="txt-pnl">
            <h6>MEGALODON</h6>
            <p>Achieve at </p>
            <p>10,000,000</p>
            <br />
            <span className="brdr-span"></span>
               <br />
            <span>
              <b>6%</b>
            </span>
            <p> You receive of the companies profits</p>
          </div>
        </li>
        <li>
          <Link to="/">
            <div className="img-pnl">
              <span className="check-span"> Level 8</span>
              <span className="lock-span"> Level 8</span>
              <div className="img">
                <img src={THEKRAKEN} alt="THE KRAKEN" />
              </div>
            </div>
            <div className="txt-pnl">
              <h6>THE KRAKEN</h6>
              <p>Achieve at </p>
              <p>12,000,000</p>
              <br />
              <span className="brdr-span"></span>
                 <br />
              <span>
                <b>10%</b>
              </span>
              <p> You receive  of the companies profits</p>
            </div>
          </Link>
        </li>
      </ul>
    </>
  );
}
export default RewardFishList;
