import React from "react";
import Accordion from "react-bootstrap/Accordion";
function Frequentlyquestion() {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span></span>What is Algotech?
          </Accordion.Header>
          <Accordion.Body>
          Algotech is a cutting-edge decentralized algorithmic trading platform designed specifically for the fast-paced world of trading. With its advanced algorithms and machine learning capabilities, Algotech empowers traders to navigate the complex markets with efficiency, precision, and accuracy.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span></span>What is the minimum purchase for ALGT tokens?

          </Accordion.Header>
          <Accordion.Body>
          The minimum purchase for ALGT tokens is $25 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span></span>
What cryptocurrencies do you accept as payment?

          </Accordion.Header>
          <Accordion.Body>
          We accept all major cryptocurrency payment e.g. Bitcoin, Ethereum, USDT, USDC, Solana, Dogecoin, Shiba Inu, BNB, Litecoin, Bitcoin Cash etc.
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="3">
          <Accordion.Header>
            <span></span>Is Algotech audited?
          </Accordion.Header>
          <Accordion.Body>
 
The Algotech smart contract has undergone a comprehensive audit conducted by the cybersecurity firm Snipe Finance, and our core team have also undergone an intense KYC Audit by Snipe Finance.

          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span></span>How Does Algotech Token ALGT Work?

          </Accordion.Header>
          <Accordion.Body>
          The ALGT token is to utilise income and dividends to early investors spending hundreds of millions of pounds on R&D for futuristic strategies such as hedging, mean reversion etc.

ALGT early investors will receive voting governance rights and will own parts of the software and will receive dividends from the profits the fund already makes.

          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <span></span>What is the ALGT Supply?

          </Accordion.Header>
          <Accordion.Body>
          The total supply is 350,000,000 ALGT.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Frequentlyquestion;
